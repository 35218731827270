import API, { APIV3 } from "../_config";

export interface PostJobType {
  title: string;
  description: string;
  location: string;
  latitude?: number;
  longitude?: number;
  task_type: string;
  due_date: Date;
  payment_type: string;
  flexible_time: boolean;
  budget_plan: number;
  // TODO:add attachment
}

export function postJob(params: PostJobType, token: string) {
  return APIV3.post(`/tasks/create`, JSON.stringify(params), {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function editJob(taskSlug: string, data: PostJobType, token: string) {
  return APIV3.put(`tasks/${taskSlug}`, JSON.stringify(data), {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchBudgetPlans() {
  return API.get(`budget-plans`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function mediaUpload(file: any) {
  return API.post(`media/temp-attachment`, file, {
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${file._boundary}`,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export const callAI = async (prompt: any) => {
  return APIV3.post(`ai/generate-description`, { prompt: prompt })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
};
