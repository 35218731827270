import { useHistory, useLocation } from "react-router-dom";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  fetchAccount,
  fetchBankAccount,
  fetchBillingAddress,
  fetchJob,
} from "../../../../api/exploreJob";
import {
  AccountInformation,
  Filter,
  Job,
  JobDetails,
  Offer,
  Pages,
} from "../../type";
import { useMediaQuery, useTheme } from "@mui/material";
import { useQuery } from "react-query";

const defaultFilter = {
  task_type: "Both",
  min_price: 5,
  max_price: 9999,
  distance: 100,
  location: "Harbord Village",
  current_lng: "-79.4108213",
  current_lat: "43.6615847",
};

interface ExploreJobContextType {
  Column1?: Pages;
  setColumn1: Dispatch<SetStateAction<Pages | undefined>>;
  Column2?: Pages;
  setColumn2: Dispatch<SetStateAction<Pages | undefined>>;
  Column3?: Pages;
  setColumn3: Dispatch<SetStateAction<Pages | undefined>>;
  Jobs?: Job[];
  setJobs: Dispatch<SetStateAction<Job[] | undefined>>;
  Job?: Job;
  setJob: Dispatch<SetStateAction<Job | undefined>>;
  Filter?: Filter;
  setFilter: Dispatch<SetStateAction<Filter | undefined>>;
  Finalfilter?: any;
  setFinalfilter: Dispatch<SetStateAction<any | undefined>>;
  Categories?: any[];
  setCategories: Dispatch<SetStateAction<string[]>>;
  Sort?: string;
  setSort: Dispatch<SetStateAction<string | undefined>>;
  Order?: string;
  setOrder: Dispatch<SetStateAction<string | undefined>>;
  JobDetail?: JobDetails;
  setJobDetail: Dispatch<SetStateAction<JobDetails | undefined>>;
  SearchQuery?: string;
  setSearchQuery: Dispatch<SetStateAction<string | undefined>>;
  AccountInformation?: any;
  setAccountInformation?: any;
  Offer?: Offer;
  setOffer: Dispatch<SetStateAction<Offer | undefined>>;
  PhoneVerify?: string;
  setPhoneVerify?: Dispatch<SetStateAction<string | undefined>>;
  showSkeleton?: boolean;
  setShowSkeleton?: any;
  direction?: any;
  setDirection?: any;
  offerStatus?: any;
  setOfferStatus?: any;
  ViewList?: boolean;
  setViewList: Dispatch<SetStateAction<boolean>>;
  TransferToChat?: boolean;
  setTransferToChat: Dispatch<SetStateAction<boolean>>;
  showJob?: any;
  setShowJob?: any;
  hashCheckToken?: string;
  setHashCheckToken?: any;
  haveBankAccount?: string;
  setHaveBankAccount?: any;
  haveBilling?: string;
  setHaveBilling?: any;
  province?: string;
  setProvince?: any;
  billingAddressField?: any;
  setBillingAddressField?: any;
  mapLoad?: boolean;
  setMapLoad?: any;
  quickOffer?: any;
  setQuickOffer?: any;
  newJobs?: any;
  setNewJobs?: any;
  filterCategory?: any;
  setFilterCategory?: any;
  onesignalSetting?: boolean;
  setOneSignalSetting?: any;
}
const ExploreJobContext = createContext<ExploreJobContextType>({
  setColumn1(value): void {},
  setColumn2(value): void {},
  setColumn3(value): void {},
  setCategories(value): void {},
  Filter: defaultFilter,
  setFilter(value): void {},
  setFinalfilter(value): void {},
  setJobs(value): void {},
  setJob(value): void {},
  setSort(value): void {},
  setOrder(value): void {},
  setJobDetail(value): void {},
  setSearchQuery(value): void {},

  setOffer(value): void {},
  setPhoneVerify(value): void {},
  setViewList(value): void {},
  setTransferToChat(value): void {},
});

export default ExploreJobContext;
interface ExploreJobContextProviderType {
  children: ReactNode;
}
export const ExploreJobContextProvider = ({
  children,
}: ExploreJobContextProviderType): JSX.Element => {
  const { breakpoints } = useTheme();
  const history = useHistory();
  const [Column1, setColumn1] = useState<Pages>();
  const [Column2, setColumn2] = useState<Pages>();
  const [Column3, setColumn3] = useState<Pages>();
  const [Jobs, setJobs] = useState<Job[]>();
  const [Job, setJob] = useState<Job>();
  const [Filter, setFilter] = useState<Filter | undefined>(defaultFilter);
  const [Finalfilter, setFinalfilter] = useState<any | undefined>();
  const [Categories, setCategories] = useState<any>([]);
  const [Sort, setSort] = useState<string | undefined>("Most Recent");
  const [Order, setOrder] = useState<string | undefined>("Ascending");
  const [JobDetail, setJobDetail] = useState<JobDetails>();
  const [SearchQuery, setSearchQuery] = useState<string>();
  const location = useLocation();
  const [Offer, setOffer] = useState<Offer>();
  const [PhoneVerify, setPhoneVerify] = useState<string>();
  const [AccountInformation, setAccountInformation] =
    useState<AccountInformation>();
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  const [direction, setDirection] = useState<any>();
  const [offerStatus, setOfferStatus] = useState<any>();
  const [ViewList, setViewList] = useState<boolean>(false);
  const [TransferToChat, setTransferToChat] = useState<boolean>(false);
  const [showJob, setShowJob] = useState<any>();
  const isMobile = useMediaQuery(breakpoints.down("md"));
  const timerRef = useRef<any>();
  const [hashCheckToken, setHashCheckToken] = useState<string>("");
  const [haveBankAccount, setHaveBankAccount] = useState<string>("");
  const [haveBilling, setHaveBilling] = useState<string>("");
  const [province, setProvince] = useState<string>("Ontario");
  const [billingAddressField, setBillingAddressField] = useState<any>({
    HouseNo: "",
    Postcode: "",
    StreetName: "",
    Neighborhood: "",
    City: "Toronto",
    Province: "Ontario",
    Country: "CA",
  });
  const [mapLoad, setMapLoad] = useState<boolean>(false);
  const [quickOffer, setQuickOffer] = useState<any>();
  const [newJobs, setNewJobs] = useState<any>([]);
  const [filterCategory, setFilterCategory] = useState<any>([]);
  const [onesignalSetting, setOneSignalSetting] = useState<any>(false);

  const slug = location.pathname.split("/explore-tasks")[1]?.substring(1);

  const handleGetDirectionClick = () => {
    setDirection({ lat: JobDetail?.latitude, lng: JobDetail?.longitude });
  };

  useEffect(() => {
    setColumn1(Pages.Jobs);
    setOffer({ offer_price: null, message: "" });
    if (isMobile) {
      if (location.hash === "#on-map" && JobDetail) {
        setViewList(true);
        setColumn2(undefined);
        if (JobDetail) handleGetDirectionClick();
      } else {
        setViewList(false);
        setColumn2(Pages.JobDetailsSkeleton);
        if (slug) {
          fetchJob(slug)
            .then((response) => {
              setJobDetail(response.data);
            })
            .then(() => setColumn2(Pages.JobDetails));
        } else {
          setJobDetail(undefined);
          setColumn2(undefined);
        }
      }
    } else {
      setViewList(false);
      setColumn2(Pages.JobDetailsSkeleton);
      if (slug) {
        fetchJob(slug)
          .then((response) => {
            setJobDetail(response.data);
          })
          .then(() => setColumn2(Pages.JobDetails));
      } else {
        setJobDetail(undefined);
        setColumn2(undefined);
      }
    }
  }, [isMobile ? location : "", slug]);

  useEffect(() => {
    if (location.search !== "?Offer-Requirements") {
      setHaveBankAccount("");
      setHaveBilling("");
    }
  }, [location.search]);

  const {} = useQuery(
    "accountInformation",
    () => {
      return fetchAccount();
    },
    {
      onSuccess: (info) => {
        setAccountInformation(info.data);
      },
    }
  );

  useEffect(() => {
    if (
      history.location.pathname === "/explore-tasks" &&
      !history.location.search
    ) {
      setColumn1(Pages.Jobs);
    }
    if (location.search === "?Filter" && location.hash === "#Filters") {
      setColumn1(Pages.Filter);
    }
    if (location.search === "?Filter" && location.hash === "#categories") {
      setColumn1(Pages.Categories);
    } else if (location.search === "?Filter" && location.hash === "#Sort") {
      setColumn1(Pages.Sort);
    } else if (
      location.search === "?Filter" &&
      location.hash === "#Task-Type"
    ) {
      setColumn1(Pages.JobType);
    } else if (
      location.search === "?Filter" &&
      location.hash === "#Neighborhood"
    ) {
      setColumn1(Pages.Suburb);
    } else if (location.search === "?Notification") {
      setColumn2(Pages.Notifications);
    } else if (location.search === "?alert" && !location.hash) {
      setColumn1(Pages.JobAlerts);
    } else if (
      (location.search === "?add-alert" ||
        location.search === "?edit-alert" ||
        location.search === "?set-alert") &&
      !location.hash
    ) {
      setColumn1(Pages.AddJobAlert);
    } else if (
      (location.search === "?add-alert" ||
        location.search === "?edit-alert" ||
        location.search === "?set-alert") &&
      location.hash === "#title"
    ) {
      setColumn1(Pages.AlertTitle);
    } else if (
      (location.search === "?add-alert" ||
        location.search === "?edit-alert" ||
        location.search === "?set-alert") &&
      location.hash === "#task-type"
    ) {
      setColumn1(Pages.JobType);
    } else if (
      (location.search === "?add-alert" ||
        location.search === "?edit-alert" ||
        location.search === "?set-alert") &&
      location.hash === "#neighborhood"
    ) {
      setColumn1(Pages.Suburb);
    } else if (
      (location.search === "?add-alert" ||
        location.search === "?edit-alert" ||
        location.search === "?set-alert") &&
      location.hash === "#categories"
    ) {
      setColumn1(Pages.Categories);
    }
  }, [location.search, location.hash, Column1]);

  return (
    <ExploreJobContext.Provider
      value={{
        Column1,
        setColumn1,
        Column2,
        setColumn2,
        Column3,
        setColumn3,
        Jobs,
        setJobs,
        Job,
        setJob,
        Filter,
        setFilter,
        Finalfilter,
        setFinalfilter,
        Categories,
        setCategories,
        Sort,
        setSort,
        Order,
        setOrder,
        JobDetail,
        setJobDetail,
        SearchQuery,
        setSearchQuery,
        Offer,
        setOffer,
        PhoneVerify,
        setPhoneVerify,
        AccountInformation,
        setAccountInformation,
        showSkeleton,
        setShowSkeleton,
        direction,
        setDirection,

        offerStatus,
        setOfferStatus,
        ViewList,
        setViewList,
        TransferToChat,
        setTransferToChat,
        showJob,
        setShowJob,
        hashCheckToken,
        setHashCheckToken,
        haveBankAccount,
        setHaveBankAccount,
        haveBilling,
        setHaveBilling,
        province,
        setProvince,
        billingAddressField,
        setBillingAddressField,
        mapLoad,
        setMapLoad,
        quickOffer,
        setQuickOffer,
        newJobs,
        setNewJobs,
        filterCategory,
        setFilterCategory,
        onesignalSetting,
        setOneSignalSetting,
      }}
    >
      {children}
    </ExploreJobContext.Provider>
  );
};
