import { Box, BoxProps, SxProps, Theme } from "@mui/material";

interface IconPropsType extends BoxProps {
  weight?: number;
  grade?: number;
  size?: number;
  handleClick?: any;
  iconSet: string;
}

export const Icon = ({
  // weight = 400,
  grade = 0,
  size = 48,
  className,
  handleClick,
  sx,
  iconSet,
  ...boxProps
}: IconPropsType): JSX.Element => {
  return (
    <Box
      component="span"
      className={`icon-${iconSet}`}
      sx={{
        fontVariationSettings: `"GRAD" ${grade},  "opsz" ${size}`,
        fontFamily: "icomoon",
        fontWeight: "normal",
        fontStyle: "normal",
        fontSize: "24px",
        display: "inline-block",
        lineHeight: 1,
        textTransform: "none",
        letterSpacing: "normal",
        wordWrap: "normal",
        whiteSpace: "nowrap",
        direction: "ltr",
        ...sx,
      }}
      {...boxProps}
      onClick={handleClick}
    />
  );
};
