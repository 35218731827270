import axios from "axios";

const access_token = `pk.eyJ1IjoidGFza3BpbiIsImEiOiJjbGpkN3o1MnQycmgzM2ZxbjU5eG81Y2tiIn0.wc_JnSfpWOKj7ymfu4WVtQ`;
export function getCurrentLocations(searchText: string) {
  return axios
    .get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json?country=ca&limit=1&types=address&access_token=${access_token}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
