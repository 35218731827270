import { Dispatch, SetStateAction, useCallback, useState } from "react";

import {
  Box,
  Fade,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

import { Step } from "../Step";
import {
  Control,
  Controller,
  FieldErrorsImpl,
  FieldValues,
  UseFormRegister,
  UseFormTrigger,
} from "react-hook-form";

const ITEMS = [
  {
    label: "Less than $250",
    id: 1,
  },
  {
    label: "$250 to $500",
    id: 2,
  },
  {
    label: "$500 to $1000",
    id: 3,
  },
  {
    label: "More than $1000",
    id: 4,
  },
  {
    label: "Add a specific amount",
    id: 0,
  },
];
interface BudgetPlanItemType {
  id: number;
  title: string;
}
interface BudgetPropsType {
  errors?: FieldErrorsImpl<{
    [x: string]: any;
  }>;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  budgetPlanItem: BudgetPlanItemType[];
  setTaskData?: any;
  taskData?: any;
}

export const Budget = ({
  errors,
  register,
  control,
  trigger,
  budgetPlanItem,
  setTaskData,
  taskData,
}: BudgetPropsType): JSX.Element => {
  const [focused, setFocused] = useState<boolean>(false);
  const { palette, breakpoints } = useTheme();
  const [focus, setFocus] = useState(false);

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
  };

  // const handleFocus = useCallback(() => {
  //   setFocused(true);
  // }, []);

  const isUpMd = useMediaQuery(breakpoints.up("md"));
  return (
    <Step title="Budget">
      <Box sx={{ width: { xs: "100%", sm: "558px" }, m: "0 auto" }}>
        <Typography
          variant="h5"
          sx={{
            color: palette.neutral.max,
            mb: "18px",
          }}
        >
          What is your budget for this task?
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {isUpMd && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                ml: "-5px",
                minWidth: "250px",
              }}
            >
              <FormControl>
                <RadioGroup name="row-radio-buttons-group">
                  {ITEMS?.map((item, index) => (
                    <Controller
                      key={item?.id}
                      control={control}
                      name="budget_plan"
                      render={({ field: { ref, onChange, ...field } }) => (
                        <FormControlLabel
                          key={index}
                          sx={{ mb: 2 }}
                          value={item.label}
                          onChange={(e) => {
                            setTaskData((current: any) => ({
                              ...current,
                              budget_plan: item.id,
                            }));
                          }}
                          control={
                            <Radio
                              {...field}
                              inputRef={ref}
                              sx={{
                                color: palette.neutral.max,
                                mr: -0.5,
                                height: "24px",
                              }}
                              checked={taskData.budget_plan === item.id}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              sx={{ color: palette.neutral.max }}
                            >
                              {item.label}
                            </Typography>
                          }
                        />
                      )}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              {taskData.budget_plan === 0 && (
                <TextField
                  onFocus={handleFocus}
                  label="Budget"
                  placeholder="Budget"
                  sx={{
                    color: palette.neutral[900],
                    "& fieldset": {
                      border: `1px solid ${palette.neutral[100]}`,
                      color: palette.neutral[400],
                      transition: "border 0.3s 0.1s",
                    },
                    width: "300px",
                    mt: 1,
                    ml: 0.7,
                  }}
                  InputProps={{
                    style: {
                      marginTop: -0.5,
                    },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ color: "black !important" }}
                      >
                        <Typography sx={{ mr: -0.6, mt: 0.1 }}>$</Typography>
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(e) => {
                    if (!/\d/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  {...register("budget", {
                    onBlur: handleBlur,
                    required: true,
                    onChange: (e) => {
                      e.persist();
                      trigger("budget");

                      setTaskData((current: any) => ({
                        ...current,
                        budget: e.target.value,
                      }));
                    },
                    min: {
                      value: 5,
                      message: "The price must be between $5 and $9999",
                    },
                    max: {
                      value: 9999,
                      message: "The price must be between $5 and $9999",
                    },
                  })}
                  inputProps={{ inputMode: "numeric" }}
                  helperText={
                    errors?.budget?.message && (
                      <Typography
                        component="span"
                        variant="label"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: palette.error.main,
                        }}
                      >
                        <>
                          <ErrorRoundedIcon
                            sx={{ mr: 0.5, fontSize: "16px" }}
                          />
                          {errors.budget.message}
                        </>
                      </Typography>
                    )
                  }
                  FormHelperTextProps={{ sx: { mx: 0 } }}
                  error={Boolean(errors?.budget)}
                />
              )}
            </Box>
          )}
          <Box sx={{ display: { xs: "none", md: "block" }, ml: 40 }}>
            <Box
              sx={{
                display: "block",
                alignItems: "center",
                position: "absolute",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "48px",
                  height: "48px",
                  borderRadius: "8px",
                  backgroundColor: palette.primary[50],
                  mt: -0.5,
                  mb: { md: "6px" },
                  mr: { md: "", xs: 1 },
                }}
              >
                <TipsAndUpdatesOutlinedIcon
                  sx={{
                    color: palette.primary.main,
                    width: "30px",
                    height: "30px",
                  }}
                />
              </Box>
              <Box sx={{ width: { lg: "227px", md: "120px" } }}>
                <Typography variant="caption">
                  You can negotiate the final price of your task.
                </Typography>
              </Box>
            </Box>
          </Box>
          {!isUpMd && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              {ITEMS.map(({ label, id }, index) => (
                <Box
                  key={index}
                  component="label"
                  htmlFor={`budget_plan-${id}`}
                  sx={{
                    display: "flex",
                    justifyContent:
                      label === "Add a specific amount" ? "left" : "center",
                    px: label === "Add a specific amount" ? "16px" : "0",
                    alignItems: "center",
                    border: {
                      md: `${
                        taskData.budget_plan === id
                          ? `1px solid ${palette.primary.main}`
                          : `1px solid ${palette.neutral[100]}`
                      }`,
                      xs: `${
                        taskData.budget_plan === id
                          ? `2px solid ${palette.primary.main}`
                          : `2px solid ${palette.neutral[100]}`
                      }`,
                    },
                    borderRadius: "8px",
                    width: "calc(50% - 8px)",
                    height: "51px",
                    cursor: "pointer",
                    outline: `${
                      taskData.budget_plan === id ? "3px solid transparent" : ""
                    }`,
                    outlineWidth: "2px",
                    outlineColor: { xs: "none", lg: palette.primary.main },
                    "&:last-of-type": {
                      width: "100%",
                    },
                    input: { display: "none" },
                  }}
                >
                  <>
                    <input
                      {...register("budget_plan", {
                        required: false,
                        onChange: (e) => {
                          trigger("budget_plan");
                          const budget = e.currentTarget.value;

                          setTaskData((current: any) => ({
                            ...current,
                            budget_plan: Number(budget),
                          }));
                        },
                      })}
                      type="radio"
                      name="budget_plan"
                      value={id}
                      id={`budget_plan-${id}`}
                    />
                    <Typography variant="button">{label}</Typography>
                  </>
                </Box>
              ))}
              {taskData.budget_plan === 0 && (
                <TextField
                  fullWidth
                  onFocus={handleFocus}
                  label="Budget"
                  placeholder="Budget"
                  value={taskData?.budget}
                  sx={{
                    color: palette.neutral[900],
                    height: "51px",
                    "& fieldset": {
                      border: `1px solid ${palette.neutral[100]}`,
                      color: palette.neutral[400],
                      transition: "border 0.3s 0.1s",
                    },
                    ml: -0.5,
                    mt: "0",
                  }}
                  InputProps={{
                    style: {
                      marginTop: -0.5,
                    },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ color: "black !important" }}
                      >
                        <Typography sx={{ mr: -0.6, mt: 0.1 }}>$</Typography>
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(e) => {
                    if (!/\d/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  {...register("budget", {
                    onBlur: handleBlur,
                    required: "The price must be between $5 and $9999",
                    onChange: (e) => {
                      const re = /^[0-9\b]+$/;
                      trigger("budget");
                      const budget = e.currentTarget.value;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        setTaskData((current: any) => ({
                          ...current,
                          budget: budget,
                        }));
                      }
                    },
                    min: {
                      value: 5,
                      message: "The price must be between $5 and $9999",
                    },
                    max: {
                      value: 9999,
                      message: "The price must be between $5 and $9999",
                    },
                  })}
                  inputProps={{ inputMode: "numeric" }}
                  helperText={
                    errors?.budget?.message && (
                      <Typography
                        component="span"
                        variant="label"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: palette.error.main,
                        }}
                      >
                        <>
                          <ErrorRoundedIcon
                            sx={{ mr: 0.5, fontSize: "16px" }}
                          />
                          {errors.budget.message}
                        </>
                      </Typography>
                    )
                  }
                  FormHelperTextProps={{ sx: { mx: 0 } }}
                  error={Boolean(errors?.budget)}
                />
              )}
              {taskData.budget_plan === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 1,
                    px: 2,
                    width: "100%",
                    borderRadius: "8px",
                    backgroundColor: palette.neutral[50],
                    mt: Boolean(errors?.budget) ? "24px" : 2,
                  }}
                >
                  <TipsAndUpdatesOutlinedIcon
                    sx={{
                      color: palette.primary.main,
                      width: "30px",
                      height: "30px",
                      mr: 1,
                    }}
                  />
                  <Typography variant="caption">
                    You can negotiate the final price of your task.
                  </Typography>
                </Box>
              ) : null}
            </Box>
          )}
        </Box>
      </Box>
    </Step>
  );
};
