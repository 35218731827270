import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { SetStateAction, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { callAI } from "api/postJob/postJob";
import CustomEvents from "components/CustomEvents";

interface FooterProps {
  step: number;
  handleStep: (value: SetStateAction<number>) => void;
  disabled: boolean;
  taskData: {
    [key: string]: any;
  };
  loading?: boolean;
  setPostThisJob?: any;
  setDescriptionAI?: any;
}

export const Footer = ({
  step,
  handleStep,
  disabled,
  taskData,
  loading,
  setPostThisJob,
  setDescriptionAI,
}: FooterProps): JSX.Element => {
  const { palette } = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { breakpoints } = useTheme();
  const smallSize = useMediaQuery(breakpoints.down("md"));
  const editJobSlug: any = Cookies.get("editJob") ? Cookies.get("editJob") : "";
  const defaultValues = CustomEvents();

  const handleBack = useCallback(() => {
    if (location.search === "?Location") {
      history.push({ search: "Title" });
    } else if (location.search === "?Edit-address") {
      history.push({ search: "Location" });
    } else if (location.search === "?Date") {
      history.push({ search: "Location" });
    } else if (location.search === "?Budget") {
      history.push({ search: "Date" });
    } else if (location.search === "?Calender") {
      history.push({ search: "Date" });
    } else if (location.search === "?Time") {
      history.push({ search: "Calender" });
    } else if (location.search === "?Details") {
      history.push({ search: "Budget" });
    } else if (location.search === "?Attachments") {
      history.push({ search: "Details" });
    } else if (location.search === "?Summary") {
      history.push({ search: "Details" });
    }
  }, [handleStep, location.search, taskData]);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: palette.neutral.min,
        zIndex: 999,
      }}
    >
      <Container
        sx={{
          pb: { md: "0", xs: "4px" },
        }}
      >
        <Divider sx={{ display: { xs: "none", md: "flex" } }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: { md: "16px", xs: "0" },
            mb: { md: "16px" },
          }}
        >
          <IconButton
            onClick={handleBack}
            disabled={location.search === "?Title"}
            sx={{
              width: "133px",
              display: "flex",
              alignItems: "center",
              visibility: location.search === "?Title" ? "hidden" : "visible",
              borderRadius: "28px",
              px: "4px !important",
              py: "0px !important",
              ":hover": {
                backgroundColor: palette.primary[50],
              },
              ":active": {
                backgroundColor: palette.primary[100],
              },
            }}
          >
            <Box
              sx={{
                bgcolor: palette.primary[600],
                width: "48px",
                height: "48px",
                borderRadius: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mr: "16px",
                ml: "-4px",
              }}
            >
              <ArrowBackRoundedIcon
                sx={{
                  color: palette.neutral.min,
                  fontSize: "32px",
                }}
              />
            </Box>
            <Typography
              sx={{ mr: "20px", color: palette.neutral[900] }}
              variant="body1"
            >
              Back
            </Typography>
          </IconButton>
          <IconButton
            disabled={disabled}
            onClick={() => {
              if (location.search === "?Edit-address") {
                history.push({ search: "Location" });
              }
              if (location.search === "?Location") {
                history.push({ search: "Date" });
                window.analytics.track(
                  `webapp poster post task location`,
                  defaultValues
                );
              }
              if (
                location.search === "?Date" &&
                taskData["timeType"] === "flexible"
              ) {
                history.push({ search: "Budget" });
                window.analytics.track(
                  `webapp poster post task datetime`,
                  defaultValues
                );
              } else if (
                location.search === "?Date" &&
                taskData["timeType"] === "certainDate"
              ) {
                history.push({ search: "Calender" });
                window.analytics.track(
                  `webapp poster post task datetime`,
                  defaultValues
                );
              }
              if (location.search === "?Calender") {
                if (smallSize) {
                  history.push({ search: "Time" });
                } else {
                  history.push({ search: "Budget" });
                }
              }
              if (location.search === "?Time") {
                history.push({ search: "Budget" });
              }
              if (location.search === "?Budget") {
                history.push({ search: "Details" });
                window.analytics.track(
                  `webapp poster post task budget`,
                  defaultValues
                );
              }
              if (location.search === "?Details") {
                history.push({ search: "Summary" });
                window.analytics.track(
                  `webapp poster post task details`,
                  defaultValues
                );
              }
              if (location.search === "?Attachments") {
                history.push({ search: "Details" });
              }
              if (location.search === "?Summary") {
                window.analytics.track(
                  `webapp poster post task summary`,
                  defaultValues
                );
                if (editJobSlug) {
                  setPostThisJob("Edit");
                } else {
                  setPostThisJob("Post");
                }
              } else {
                setPostThisJob("");
              }
            }}
            type="submit"
            sx={{
              width: "133px",
              display: "flex",
              alignItems: "center",
              borderRadius: "28px",
              px: "4px !important",
              py: "4px !important",
              ":hover": {
                backgroundColor: palette.primary[50],
              },
              ":active": {
                backgroundColor: palette.primary[100],
              },
            }}
          >
            <Typography
              sx={{
                mr: "16px",
                ml: "20px",
                color: disabled ? palette.neutral[400] : palette.neutral[900],
              }}
              variant="body1"
            >
              Next
            </Typography>
            <Box
              className="icon"
              sx={{
                bgcolor: loading
                  ? "white"
                  : disabled
                  ? palette.neutral[100]
                  : palette.primary[600],
                width: "48px",
                height: "48px",
                borderRadius: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mr: "-4px",
              }}
            >
              {loading ? (
                <CircularProgress size={50} sx={{ fontSize: 20 }} />
              ) : (
                <ArrowForwardRoundedIcon
                  sx={{
                    color: disabled
                      ? palette.neutral[400]
                      : palette.neutral.min,
                    fontSize: "32px",
                  }}
                />
              )}
            </Box>
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
};
