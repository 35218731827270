export function initAccount(payload) {
  return { type: 'INIT_ACCOUNT', payload };
}

export function updateAccount(payload) {
  return { type: 'UPDATE_ACCOUNT', payload };
}

export function initNotification(payload) {
  return { type: 'INIT_NOTIFICATIONS_COUNT', payload };
}

export function updateNotification(payload) {
  return { type: 'UPDATE_NOTIFICATIONS_COUNT', payload };
}

export function initWallet(payload) {
  return { type: 'INIT_WALLET', payload };
}

export function updateWallet(payload) {
  return { type: 'UPDATE_WALLET', payload };
}

export function initSocketStatus(payload) {
  return { type: 'INIT_SOCKET', payload };
}

export function getCategoryPostJob(payload) {
  return { type: 'GetCategoryPostJob', payload };
}