export function accountReducer(state = null, action) {
    switch (action.type) {
        case 'INIT_ACCOUNT' :
            return Object.assign({}, state, action.payload);  
        case 'UPDATE_ACCOUNT' :
            state = {...state, ...action.payload};
            return state  
        default : 
            return state;
            
    }
}