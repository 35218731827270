export const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
export const APP_DEV_WEBSITE_BASE_URL =
  process.env.REACT_APP_DEV_WEBSITE_BASE_URL;
export const PUBLIC_WEBSITE_URL = process.env.REACT_APP_WEBSITE_BASE_URL;
export const PUBLIC_DEV_WEBSITE_URL =
  process.env.REACT_APP_DEV_WEBSITE_BASE_URL;
export const APP_PROFILE_URL = APP_BASE_URL + "profile";

export const GOOGLE_AUTH_CLIENT_ID =
  "582071119574-2d8rcnd0serh1eam9bjr26pcj41emr0i.apps.googleusercontent.com";
export const FACEBOOK_AUTH_CLIENT_ID = "1147513929013619";
export const APPLE_AUTH_CLIENT_ID = "com.jobtick.service";

export const MAP_BOX_TOKEN =
  "pk.eyJ1Ijoiam9idGljayIsImEiOiJja2pzMWYycWEza3RwMnpxb3BzYm1rZ3plIn0.q7vS4z3Ffxuyy0QwnOX8BA";

//export const STRIPE_KEY = "pk_test_51DMC17EdCPjjZ7tOKFdshvLgwrNkjWFc4Q3tJT4QONjr8yy9BQt4xhoE5nxIOF1PUJm8W3MjU8kn9yNCsUCYbTa400kXtgexZK"; //dev
//export const STRIPE_KEY = "pk_live_TOO68dRFoZh4b8Y5aBNHSWiu"; // product
export const STRIPE_KEY =
  "pk_test_51N8hQsItHzsuV8V5KvSV34FKCs0seLTWrb1qNos11UeexojLje7q6f0E83hza1NETLRIDpZf5slah6X6bFn3q23H00W3a1rPv8";

export const STRIPE_KEY_PRODUCTION =
  "pk_live_51N8hQsItHzsuV8V5Vh8ygFWILBxZfdhlUmjgkClue3Q06XrvI88cJcM3mEpSTXFaKAOl1XG4BT9hyrpFEuAMNP3p00KnztAm2q";

export const ONESIGNAL_CONFIG = {
  appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
  safari_web_id: "web.onesignal.auto." + process.env.REACT_APP_ONESIGNAL_APP_ID,
};

export const LIVECHAT_LICENCE_ID = "12468333";

export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export const IS_DEV = process.env.NODE_ENV === 'development';