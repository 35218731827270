import { useState } from "react";

import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TitleRoundedIcon from "@mui/icons-material/TitleRounded";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SubdirectoryArrowRightRoundedIcon from "@mui/icons-material/SubdirectoryArrowRightRounded";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";

import { Step } from "../Step";
import { useHistory, useLocation } from "react-router-dom";
interface SummaryProps {
  handleStep: (value: number) => void;
  taskData: {
    [key: string]: any;
  };
}

const BUDGETITEMS = [
  {
    label: "Less than $250",
    id: 1,
  },
  {
    label: "$250 to $500",
    id: 2,
  },
  {
    label: "$500 to $1000",
    id: 3,
  },
  {
    label: "More than $1000",
    id: 4,
  },
  {
    label: "Add a specific amount",
    id: 0,
  },
];

export const Summary = ({
  handleStep,
  taskData,
}: SummaryProps): JSX.Element => {
  const [step, setStep] = useState<string>("");
  const { palette, breakpoints } = useTheme();
  const smallSize = useMediaQuery(breakpoints.down("md"));
  const history = useHistory();
  const location = useLocation();
  const [showMore, setShowMore] = useState(false);

  return taskData ? (
    <Step title="Summary">
      <Box
        sx={{
          m: "0 auto",
          ml: { md: "290px", xs: "" },
          width: { xs: "100%", sm: "715px" },
          // overflowY: { xs: "scroll", md: "unset" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ mb: 3, display: { xs: "block", md: "none" } }}>
            <Box
              sx={{
                display: "flex",
                borderRadius: "8px",
                backgroundColor: palette.neutral[50],
                px: 2,
                py: 1,
              }}
            >
              <TipsAndUpdatesOutlinedIcon
                sx={{
                  color: palette.primary.main,
                  width: "30px",
                  height: "30px",
                  mr: 1,
                }}
              />
              <Typography variant="caption" sx={{ width: "244px" }}>
                You can jump to each section by tapping on its icon
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "baseline" },
              flexDirection: { xs: "column", md: "row" },
              position: "relative",
              mb: { xs: 4, md: 0 },
            }}
            onMouseOver={() => setStep("title")}
            onMouseLeave={() => setStep("")}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: { md: "center" },
              }}
            >
              <TitleRoundedIcon
                onClick={() => history.push({ search: "Title" })}
                sx={{
                  color:
                    !taskData["title"] ||
                    taskData["title"]?.length < 9 ||
                    taskData["title"]?.length > 100
                      ? palette.error.main
                      : palette.primary.main,
                  width: smallSize ? "25px" : "16px",
                  height: smallSize ? "25px" : "16px",
                  mr: 0.5,
                }}
              />
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    mr: 7,
                    color:
                      !taskData["title"] ||
                      taskData["title"]?.length < 9 ||
                      taskData["title"]?.length > 100
                        ? palette.error.main
                        : palette.neutral[500],
                  }}
                >
                  Task title
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    ml: { xs: 0, md: 0 },
                    mt: { xs: 1, md: 0 },
                    width: { xs: "100%", md: "447px" },
                    display: { xs: "flex", md: "none" },
                  }}
                >
                  {taskData["title"]}
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="body1"
              sx={{
                ml: { xs: 0, md: 0 },
                mt: { xs: 1, md: 0 },
                width: { xs: "300px", md: "447px" },
                display: { xs: "none", md: "flex" },
              }}
            >
              {taskData["title"]}
            </Typography>
            <Box
              onClick={() => history.push({ search: "Title" })}
              sx={{
                display: {
                  xs: "none",
                  md:
                    step === "title" ||
                    !taskData["title"] ||
                    taskData["title"]?.length < 9 ||
                    taskData["title"]?.length > 100
                      ? "flex"
                      : "none",
                },
                position: "absolute",
                right: 28,
                ":hover": { color: palette.primary.main },
                cursor: "pointer",
                alignItems: "center",
                mr: -3,
              }}
            >
              <SubdirectoryArrowRightRoundedIcon
                sx={{
                  width: "16px",
                  height: "16px",
                  rotate: "-90deg",
                  mt: "-3px",
                  mr: 0.5,
                }}
              />
              <Typography variant="caption">Jump to Task Title</Typography>
            </Box>
          </Box>
          <Divider
            sx={{
              color: palette.neutral[100],
              my: 1,
              mr: 17,
              display: { xs: "none", md: "flex" },
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "baseline" },
              flexDirection: { xs: "column", md: "row" },
              mb: { xs: 4, md: 0 },
              position: "relative",
            }}
            onMouseOver={() => setStep("location")}
            onMouseLeave={() => setStep("")}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: { md: "center" },
              }}
            >
              <PinDropOutlinedIcon
                onClick={() => history.push({ search: "Location" })}
                sx={{
                  color:
                    !taskData["locationType"] ||
                    (taskData["locationType"] === "inPerson" &&
                      !taskData["coordinates"])
                      ? palette.error.main
                      : palette.primary.main,
                  width: smallSize ? "25px" : "16px",
                  height: smallSize ? "25px" : "16px",
                  mr: 0.5,
                }}
              />
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    mr: 7,
                    color:
                      !taskData["locationType"] ||
                      (taskData["locationType"] === "inPerson" &&
                        !taskData["coordinates"])
                        ? palette.error.main
                        : palette.neutral[500],
                  }}
                >
                  Location
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    ml: { xs: 0, md: 0 },
                    mt: { xs: 1, md: 0 },
                    overflowWrap: "break-word",
                    width: { xs: "90%", md: "447px" },
                    display: { xs: "flex", md: "none" },
                  }}
                >
                  {taskData["locationType"]
                    ? taskData["locationType"] === "inPerson"
                      ? taskData["location"]
                      : "Remote"
                    : ""}
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="body1"
              sx={{
                ml: { xs: 0, md: 0 },
                mt: { xs: 1, md: 0 },
                overflowWrap: "break-word",
                width: { xs: "280px", md: "447px" },
                display: { xs: "none", md: "flex" },
              }}
            >
              {taskData["locationType"]
                ? taskData["locationType"] === "inPerson"
                  ? taskData["location"]
                  : "Remote"
                : ""}
            </Typography>
            <Box
              onClick={() => history.push({ search: "Location" })}
              sx={{
                display: {
                  xs: "none",
                  md:
                    step === "location" ||
                    !taskData["locationType"] ||
                    (taskData["locationType"] === "inPerson" &&
                      !taskData["coordinates"])
                      ? "flex"
                      : "none",
                },
                position: "absolute",
                right: 28,
                ":hover": { color: palette.primary.main },
                cursor: "pointer",
                alignItems: "center",
                mr: -2.6,
              }}
            >
              <SubdirectoryArrowRightRoundedIcon
                sx={{
                  width: "16px",
                  height: "16px",
                  rotate: "-90deg",
                  mt: "-4px",
                  mr: 0.5,
                }}
              />
              <Typography variant="caption">Jump to Location</Typography>
            </Box>
          </Box>
          <Divider
            sx={{
              color: palette.neutral[100],
              my: 1,
              mr: 17,
              display: { xs: "none", md: "flex" },
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "baseline", md: "center" },
              flexDirection: { xs: "column", md: "row" },
              mb: { xs: 4, md: 0 },
              position: "relative",
              width: { xs: "100%", md: "733px" },
            }}
            onMouseOver={() => setStep("date")}
            onMouseLeave={() => setStep("")}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: { md: "center" },
              }}
            >
              <AccessTimeRoundedIcon
                onClick={() => history.push({ search: "Date" })}
                sx={{
                  color:
                    !taskData["timeType"] ||
                    (taskData["timeType"] === "certainDate" &&
                      !taskData["due_time"])
                      ? palette.error.main
                      : palette.primary.main,
                  width: smallSize ? "25px" : "16px",
                  height: smallSize ? "25px" : "16px",
                  mr: 0.5,
                }}
              />
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    mr: 4,
                    color:
                      !taskData["timeType"] ||
                      (taskData["timeType"] === "certainDate" &&
                        !taskData["due_time"])
                        ? palette.error.main
                        : palette.neutral[500],
                  }}
                >
                  Date & Time
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    ml: { xs: 0, md: 0 },
                    mt: { xs: 1, md: 0 },
                    display: { xs: "flex", md: "none" },
                  }}
                >
                  {taskData["timeType"] === "flexible"
                    ? "Flexible"
                    : taskData["due_date"]
                    ? `${taskData["due_date"]} - ${
                        taskData["due_time"]
                          ? taskData["due_time"]?.charAt(0).toUpperCase() +
                            taskData["due_time"]?.slice(1)
                          : ""
                      }`
                    : ""}
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="body1"
              sx={{
                ml: { xs: 0, md: 0 },
                mt: { xs: 1, md: 0 },
                display: { xs: "none", md: "flex" },
              }}
            >
              {taskData["timeType"] === "flexible"
                ? "Flexible"
                : taskData["due_date"]
                ? `${taskData["due_date"]} - ${
                    taskData["due_time"]
                      ? taskData["due_time"]?.charAt(0).toUpperCase() +
                        taskData["due_time"]?.slice(1)
                      : ""
                  }`
                : ""}
            </Typography>
            <Box
              onClick={() => history.push({ search: "Date" })}
              sx={{
                display: {
                  xs: "none",
                  md:
                    step === "date" ||
                    !taskData["timeType"] ||
                    (taskData["timeType"] === "certainDate" &&
                      !taskData["due_time"])
                      ? "flex"
                      : "none",
                },
                position: "absolute",
                right: 10,
                ":hover": { color: palette.primary.main },
                cursor: "pointer",
                alignItems: "center",
                mr: -0.6,
              }}
            >
              <SubdirectoryArrowRightRoundedIcon
                sx={{
                  width: "16px",
                  height: "16px",
                  rotate: "-90deg",
                  mt: "-3px",
                  mr: 0.5,
                }}
              />
              <Typography variant="caption">Jump to Date & Time</Typography>
            </Box>
          </Box>
          <Divider
            sx={{
              color: palette.neutral[100],
              my: 1,
              mr: 17,
              display: { xs: "none", md: "flex" },
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "baseline", md: "center" },
              flexDirection: { xs: "column", md: "row" },
              mb: { xs: 4, md: 0 },
              position: "relative",
            }}
            onMouseOver={() => setStep("budget")}
            onMouseLeave={() => setStep("")}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: { md: "center" },
              }}
            >
              <MonetizationOnOutlinedIcon
                onClick={() => history.push({ search: "Budget" })}
                sx={{
                  color:
                    taskData["budget_plan"] === undefined ||
                    (taskData["budget_plan"] === 0 &&
                      (taskData["budget"] < 4 ||
                        taskData["budget"] === undefined)) ||
                    (taskData["budget_plan"] === 0 &&
                      /^[0-9]*$/.test(taskData["budget"]) === false)
                      ? palette.error.main
                      : palette.primary.main,
                  width: smallSize ? "25px" : "16px",
                  height: smallSize ? "25px" : "16px",
                  mr: 0.5,
                }}
              />
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    mr: 8,
                    color:
                      taskData["budget_plan"] === undefined ||
                      (taskData["budget_plan"] === 0 &&
                        (taskData["budget"] < 4 ||
                          taskData["budget"] === undefined)) ||
                      (taskData["budget_plan"] === 0 &&
                        /^[0-9]*$/.test(taskData["budget"]) === false)
                        ? palette.error.main
                        : palette.neutral[500],
                  }}
                >
                  Budget
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    ml: { xs: 0, md: 0.3 },
                    mt: { xs: 1, md: 0 },
                    display: { xs: "flex", md: "none" },
                  }}
                >
                  {taskData["budget_plan"] === 0
                    ? `$${taskData["budget"]}`
                    : BUDGETITEMS[taskData["budget_plan"] - 1]?.label}
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="body1"
              sx={{
                ml: { xs: 0, md: 0.3 },
                mt: { xs: 1, md: 0 },
                display: { xs: "none", md: "flex" },
              }}
            >
              {taskData["budget_plan"] === 0
                ? `$${taskData["budget"]}`
                : BUDGETITEMS[taskData["budget_plan"] - 1]?.label}
            </Typography>

            <Box
              onClick={() => history.push({ search: "Budget" })}
              sx={{
                display: {
                  xs: "none",
                  md:
                    step === "budget" ||
                    taskData["budget_plan"] === undefined ||
                    (taskData["budget_plan"] === 0 &&
                      (taskData["budget"] < 4 ||
                        taskData["budget"] === undefined)) ||
                    (taskData["budget_plan"] === 0 &&
                      /^[0-9]*$/.test(taskData["budget"]) === false)
                      ? "flex"
                      : "none",
                },
                position: "absolute",
                right: 40,
                ":hover": { color: palette.primary.main },
                cursor: "pointer",
                alignItems: "center",
                mr: -3,
              }}
            >
              <SubdirectoryArrowRightRoundedIcon
                sx={{
                  width: smallSize ? "20px" : "16px",
                  height: smallSize ? "20px" : "16px",
                  rotate: "-90deg",
                  mt: "-3.5px",
                  mr: 0.5,
                }}
              />
              <Typography variant="caption">Jump to Budget</Typography>
            </Box>
          </Box>
          <Divider
            sx={{
              color: palette.neutral[100],
              my: 1,
              mr: 17,
              display: { xs: "none", md: "flex" },
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "baseline" },
              flexDirection: { xs: "column", md: "row" },
              mb: { xs: 4, md: 0 },
              position: "relative",
            }}
            onMouseOver={() => setStep("details")}
            onMouseLeave={() => setStep("")}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <DescriptionOutlinedIcon
                onClick={() => history.push({ search: "Details" })}
                sx={{
                  color:
                    !taskData["details"] ||
                    taskData["details"]?.length < 24 ||
                    taskData["details"]?.length > 1000
                      ? palette.error.main
                      : palette.primary.main,
                  width: smallSize ? "25px" : "16px",
                  height: smallSize ? "25px" : "16px",
                  mr: 0.5,
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  mr: 8,
                  color:
                    !taskData["details"] ||
                    taskData["details"]?.length < 24 ||
                    taskData["details"]?.length > 1000
                      ? palette.error.main
                      : palette.neutral[500],
                }}
              >
                Details
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{
                  overflowWrap: "break-word",
                  // textJustify: "revert",
                  width: { xs: "90%", md: "447px" },
                  // pr: { xs: 0, md: 22 },
                  ml: { xs: 3.8, md: 0.3 },
                  mt: { xs: 1, md: 0 },
                  whiteSpace: "pre-line",
                  display: "-webkit-box",
                  WebkitLineClamp: showMore ? 20 : 4,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {taskData["details"]?.length > 200
                  ? showMore
                    ? taskData["details"]
                    : taskData["details"].substring(0, 200) + "..."
                  : taskData["details"]}
              </Typography>
              <Typography
                variant="button"
                sx={{
                  color: palette.primary.main,
                  display: taskData["details"]?.length > 200 ? "" : "none",
                  "&:hover": {
                    cursor: "pointer",
                  },
                  ml: { xs: 3.8, md: 0.3 },
                }}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "See less" : "See more"}
              </Typography>
            </Box>

            <Box
              onClick={() => history.push({ search: "Details" })}
              sx={{
                display: {
                  xs: "none",
                  md:
                    step === "details" ||
                    !taskData["details"] ||
                    taskData["details"]?.length < 24 ||
                    taskData["details"]?.length > 1000
                      ? "flex"
                      : "none",
                },
                position: "absolute",
                right: 40,
                ":hover": { color: palette.primary.main },
                cursor: "pointer",
                alignItems: "center",
                mr: -2.6,
              }}
            >
              <SubdirectoryArrowRightRoundedIcon
                sx={{
                  width: "16px",
                  height: "16px",
                  rotate: "-90deg",
                  mt: "-3px",
                  mr: 0.3,
                }}
              />
              <Typography variant="caption">Jump to Details</Typography>
            </Box>
          </Box>
          {taskData["files"]?.length > 0 ? (
            <>
              <Divider
                sx={{
                  color: palette.neutral[100],
                  my: 1,
                  mr: 17,
                  display: { xs: "none", md: "flex" },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: { xs: "baseline", md: "center" },
                  flexDirection: { xs: "column", md: "row" },
                  mb: { xs: 4, md: 0 },
                  position: "relative",
                  width: { xs: "100%", md: "733px" },
                }}
                onMouseOver={() => setStep("attachment")}
                onMouseLeave={() => setStep("")}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AttachFileIcon
                    onClick={() => history.push({ search: "Attachments" })}
                    sx={{
                      color: palette.primary.main,
                      width: smallSize ? "25px" : "16px",
                      height: smallSize ? "25px" : "16px",
                      mr: 0.5,
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ mr: 3.3, color: palette.neutral[500] }}
                  >
                    Attachments
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  sx={{ ml: { xs: 3.8, md: 0 }, mt: { xs: 1, md: 0 } }}
                >
                  {taskData["files"].length === 1
                    ? `${taskData["files"].length} file`
                    : `${taskData["files"].length} files`}
                </Typography>
                {step === "attachment" && (
                  <Box
                    onClick={() => history.push({ search: "Attachments" })}
                    sx={{
                      display: { xs: "none", md: "flex" },
                      position: "absolute",
                      right: 10,
                      ":hover": { color: palette.primary.main },
                      cursor: "pointer",
                      alignItems: "center",
                      mr: -0.8,
                    }}
                  >
                    <SubdirectoryArrowRightRoundedIcon
                      sx={{
                        width: "16px",
                        height: "16px",
                        rotate: "-90deg",
                        mt: "-3px",
                        mr: 0.5,
                      }}
                    />
                    <Typography variant="caption">
                      Jump to Attachments
                    </Typography>
                  </Box>
                )}
              </Box>
            </>
          ) : null}
        </Box>
      </Box>
    </Step>
  ) : (
    <></>
  );
};
