import React from "react";
import Cookies from "js-cookie";
import {
  MAP_BOX_TOKEN,
  PUBLIC_WEBSITE_URL,
  APP_BASE_URL,
} from "constants/config";

import useIsMounted from "react-is-mounted-hook";
import Axios from "axios";

const Levels = [
  {
    icon: "/images/profile/level1.svg",
  },
  {
    icon: "/images/profile/level2.svg",
  },
  {
    icon: "/images/profile/level3.svg",
  },
  {
    icon: "/images/profile/level4.svg",
  },
];

export function getLevelIconUrl(tierId) {
  switch (tierId) {
    case 1:
      return Levels[1].icon;
    case 2:
      return Levels[2].icon;
    case 3:
      return Levels[3].icon;
    case 4:
      return Levels[4].icon;
    default:
      return undefined;
  }
}

export function getUrlQuery() {
  var pairs = window.location.search.substring(1).split("&"),
    obj = {},
    pair,
    i;

  for (i in pairs) {
    if (pairs[i] === "") continue;

    pair = pairs[i].split("=");
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }

  return obj;
}

export const getPMBrand = (brand) => {
  switch (brand) {
    case "amex":
      return "American Express";
    case "visa":
      return "Visa";
    case "mastercard":
      return "Master Card";
    case "jcb":
      return "JCB";

    default:
      return brand;
  }
};

export const getPMIconUrl = (type) => {
  switch (type) {
    case "visa":
      return require("assets/vectors/bank-visa.svg").default;
    case "mastercard":
      return require("assets/vectors/bank-mc.svg").default;
    default:
      return "";
  }
};

export const redircetToLogin = () => {
  if (window.location.pathname === "/login") return undefined;

  localStorage.removeItem("token");
  Cookies.remove("token", {
    domain: window.location.origin.includes("localhost")
      ? undefined
      : ".taskpin.ca",
  });
  if (process.env.NODE_ENV === "development")
    window.location.pathname = "/login";
  else window.location.href = PUBLIC_WEBSITE_URL + "logout";
};

export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return undefined;
}

export function useGeoLocation() {
  const isMounted = useIsMounted();
  const [Location, setLocation] = React.useState();
  const [LocationInfo, setLocationInfo] = React.useState();

  React.useEffect(() => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const { coords } = pos;
        setLocation({
          latitude: coords.latitude,
          longitude: coords.longitude,
        });
      });
    }
  }, []);

  React.useEffect(() => {
    if (Location) {
      let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${Location.longitude},${Location.latitude}.json?access_token=${MAP_BOX_TOKEN}&limit=1&country=AU&types=locality%2Cplace&autocomplete=true`;
      Axios.get(url)
        .then((payload) => {
          if (
            isMounted &&
            payload?.data?.features &&
            payload.data.features[0]
          ) {
            let pos = payload?.data?.features[0];
            setLocationInfo({
              text: pos.text,
              longitude: pos.center[0],
              latitude: pos.center[1],
            });
          }
        })
        .catch();
    }
    // eslint-disable-next-line
  }, [Location]);

  return {
    setLocation: (lat, lng) => setLocation({ latitude: lat, longitude: lng }),
    getLocationInfo: () => {
      return LocationInfo;
    },
  };
}

export function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function getDistance([lat1, lng1], [lat2, lng2]) {
  if (!Location) return undefined;
  var radlat1 = (Math.PI * lat1) / 180;
  var radlat2 = (Math.PI * lat2) / 180;
  var theta = lng1 - lng2;
  var radtheta = (Math.PI * theta) / 180;
  var dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  //Kilometer
  dist = dist * 1.609344;
  return dist;
}
export const approximateCoordinate = (coordinate) => {
  const amount = Math.random() / 60 - Math.random() / 60;

  return Number(coordinate) + amount;
};
export const isDevelopment = APP_BASE_URL !== "https://app.taskpin.ca/";
