import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  FieldErrorsImpl,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormReset,
} from "react-hook-form";
import { DraftJob } from "./DraftJob";
import { NewJob } from "./NewJob";
import { Step } from "../Step";
import Cookies from "js-cookie";

interface TitlePropsType {
  register: UseFormRegister<FieldValues>;
  reset: UseFormReset<FieldValues>;
  errors?: FieldErrorsImpl<{
    [x: string]: any;
  }>;
  clearErrors: UseFormClearErrors<FieldValues>;
  taskData?: any;
  setTaskData?: any;
  loading?: boolean;
}

export const Title = ({
  register,
  reset,
  errors,
  clearErrors,
  taskData,
  setTaskData,
  loading,
}: TitlePropsType): JSX.Element => {
  const [draft, setDraft] = useState<boolean>(false);
  const editJobSlug = Cookies.get("editJob");
  const copyJobSlug = Cookies.get("copyJob");
  useEffect(() => {
    if (Cookies.get("draft")) {
      setDraft(true);
    } else {
      setDraft(false);
    }
  }, []);

  const draftName: any = Cookies.get("draft");

  return (
    <Step title="Title">
      {draft && !editJobSlug && !copyJobSlug && (
        <DraftJob
          handleDraft={setDraft}
          setTaskData={(data) => {
            setTaskData(data);
            reset(data);
          }}
          draftName={draftName}
        />
      )}
      <NewJob
        register={register}
        errors={errors}
        clearErrors={clearErrors}
        taskData={taskData}
        setTaskData={setTaskData}
        loading={loading}
      />
    </Step>
  );
};
