import API, { APIV2, APIV3 } from "api/_config";

interface verifyEmailOtpProps {
  email?: string;
  otp?: any;
  forgotPassword?: boolean;
}

export function fetchAccount() {
  return API.get(`account`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchBillingAddress() {
  return API.get(`account/billingaddress`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function updateBillingAddress(data: any) {
  return API.post(`account/billingaddress`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteBankAccount(id: number) {
  return API.delete(`account/bankaccount/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function addBankAccount(data: any) {
  return API.post(`account/bankaccount`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchBankAccount() {
  return API.get(`account/bankaccount`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function fetchPaymentHistory(params: any, howShow: any, role?: any) {
  if (howShow !== "Custom") {
    return APIV3.get(`/payments/list?${howShow}`, { params: role })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw e;
      });
  } else {
    return API.get(`/payments/list`, {
      params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw e;
      });
  }
}

export function fetchPaymentHistoryCSV(role?: any) {
  return APIV3.get(`payments/payment_csv?role_as=${role}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchUserPortfolio() {
  return API.get(`profile/portfolio/all`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function addUserPortfolio(file: any) {
  return APIV3.post(`profile/portfolio/add`, file)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function updatePortfolio(file: any) {
  return APIV3.post(`profile/portfolio/update`, file)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function allPortfolioImage(id: any) {
  return API.get(`profile/portfolio/list/image/:${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function portfolioDetails(id: number) {
  return APIV3.get(`profile/portfolio/details/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function deletePortfolio(id: number) {
  return API.get(`profile/portfolio/delete/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
// export function uploadUserPortfolio(file: any) {
//   let formData = new FormData();
//   formData.append("media", file, file.fileName);
//   return API.post(`profile/portfolio`, formData, {
//     headers: {
//       accept: "application/json",
//       "Accept-Language": "en-US,en;q=0.8",
//       "Content-Type": `multipart/form-data; boundary=${formData}`,
//     },
//   })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((e) => {
//       throw e;
//     });
// }
// export function deleteUserPortfolio(portfolioId: any) {
//   return API.delete(`profile/portfolio/${portfolioId}`)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((e) => {
//       throw e;
//     });
// }
export function requestSendEmailOtp(email: string) {
  return API.post(`email-verification/resent-otp`, {
    email,
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function verifyEmailOtp({
  email,
  otp,
  forgotPassword,
}: verifyEmailOtpProps) {
  return API.post(
    forgotPassword ? `/reset-password/otp-verify` : `email-verification`,
    {
      email,
      otp,
    }
  )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function changePassword(data: any) {
  return API.post(`account/change-password`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function phoneGenerateOtp(data: any) {
  return API.post(`account/phone_send_otp`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function phoneVerification(data: any) {
  return API.post(`account/phone_verification`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchNotificationSettings(type: any) {
  return APIV2.get(`/notifications/settings`, {
    params: {
      type,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function setSMSNotificationSetting(settings: any) {
  return APIV2.post(`/notifications/settings?type=sms`, settings)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function setPushNotificationSetting(settings: any) {
  return APIV2.post(`/notifications/settings?type=push`, settings)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function setEmailNotificationSetting(settings: any) {
  return APIV2.post(`/notifications/settings?type=email`, settings)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchPaymentMethod() {
  return API.get(`payments/method`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function addPaymentMethod(pm_token: any) {
  return API.post(`payments/method`, {
    pm_token,
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchJobInvoice(taskSlug: any) {
  return API.get(`tasks/${taskSlug}/invoice`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function changeEmailAddress(email: string) {
  return API.post(`profile/change_email`, {
    email,
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function editProfile(data: any) {
  return APIV2.post(`profile/update`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function editProfileInfo(data: any) {
  return API.post(`profile/info`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteUser(token: any) {
  return API.post(`profile/delete_account`, {
    token,
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchJob(slug: string) {
  return API.get(`tasks/${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchProfile(slug: any) {
  return API.get(`public-profile/username/${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function updateSkills(data: any) {
  return API.post(`profile/skills`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchSkills() {
  return API.get(`skills?query`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

// NEW

export function fetchMySkills() {
  return API.get(`profile/skill/list`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchAllSkills() {
  return API.get(`profile/skill/all`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function addSkill(skills: any) {
  return API.post(`profile/skill/add`, {
    skills,
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteSkill(id: any) {
  return API.get(`profile/skill/delete/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function fetchReviews(id: number, sort?: string) {
  return API.get(`/profile/${id}/reviews/worker?sort=${sort}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function mediaUpload(file: any) {
  return API.post(`media/temp-attachment`, file, {
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${file._boundary}`,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function uploadUserAvatar(file: any) {
  return API.post(`profile/upload-avatar`, file, {
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${file._boundary}`,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
