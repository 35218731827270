import { useTheme } from "@mui/material";
import FirstPage from "./Pages/firstPage";
import Skills from "./Pages/skills";
import { useState } from "react";
import Location from "./Pages/location";

const WhatsNew = (): JSX.Element => {
  const { palette } = useTheme();
  const [pages, setPages] = useState<string>("First");

  return pages === "First" ? (
    <FirstPage setPages={setPages} />
  ) : pages === "Skills" ? (
    <Skills setPages={setPages} />
  ) : (
    <Location setPages={setPages} />
  );
};

export default WhatsNew;
