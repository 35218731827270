import React, { useEffect } from "react";
import { fetchAccount } from "./api/profile";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "utils/history";
import Layout from "./layout";
import * as snippet from "@segment/snippet";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  initAccount,
  initNotification,
  initWallet,
  initSocketStatus,
  getCategoryPostJob,
} from "./store/actions";
import router from "router";
import useIsMounted from "react-is-mounted-hook";
import { redircetToLogin } from "utils";
import { fetchUnreadNotificationsCount } from "api/notifications";
import { fetchPaymentMethod } from "api/payment";
import { SocketContext } from "index";
import { fetchCategories } from "api/jobs";
import { firebase_config } from "utils/firebase";
import { initializeApp } from "firebase/app";
import { MetaHeadEmbed } from "@phntms/react-share";
import AlertNotif from "components/AlertNotif";
import toast from "react-hot-toast";
import "./style/icon.css";
import "./style/global.css";
import { isDevelopment } from "utils";
import useAccountInfoStore from "store/zustand/accountInformation";
import { APP_DEV_WEBSITE_BASE_URL } from "constants/config";

// import "@fontsource/material-symbols-rounded/400.css";

const Signin = React.lazy(() => import("./components/AuthModal"));

function mapDispatchToProps(dispatch) {
  return {
    initAccount: (initialPayload) => dispatch(initAccount(initialPayload)),
    initNotification: (initialPayload) =>
      dispatch(initNotification(initialPayload)),
    initSocketStatus: (initialPayload) =>
      dispatch(initSocketStatus(initialPayload)),
    initWallet: (initialPayload) => dispatch(initWallet(initialPayload)),
    getCategoryPostJob: (initialPayload) =>
      dispatch(getCategoryPostJob(initialPayload)),
  };
}
function mapStateToProps(state) {
  return {
    account: state.account,
  };
}

function App({
  account,
  initAccount,
  initNotification,
  initSocketStatus,
  initWallet,
  getCategoryPostJob,
}) {
  const isMounted = useIsMounted();
  const setAccountInfo = useAccountInfoStore(
    (state) => state.setAccountInformation
  );

  useEffect(() => {
    initializeApp(firebase_config);
  }, []);
  const socket = React.useContext(SocketContext);

  if (!localStorage.getItem("token") && window.location.pathname !== "/login") {
    if (window.location.pathname.includes("explore-tasks")) {
      window.location.href =
        APP_DEV_WEBSITE_BASE_URL + window.location.pathname;
    } else {
      redircetToLogin();
    }
  }

  const getAccount = () => {
    fetchAccount()
      .then((payload) => {
        if (isMounted) {
          if (payload) {
            if (payload.success) {
              initAccount(payload.data);
              setAccountInfo(payload.data);
            }
          }
        }
      })
      .catch((reason) => {
        if (isMounted && reason.response) {
          if (
            reason.response.status === 401 ||
            reason.response.status === 403
          ) {
            toast.custom(() => (
              <AlertNotif
                title="Unauthenticated, Redirecting to website."
                type="error"
              />
            ));
            // redircetToLogin();
            return false;
          }
          return { error: true, response: reason.response };
        }
      });
  };

  const getUnreadNotificationsCount = () => {
    fetchUnreadNotificationsCount()
      .then((payload) => {
        if (isMounted) {
          if (payload) {
            if (payload.success) {
              initNotification(payload.data);
            }
          }
        }
      })
      .catch((reason) => {
        if (isMounted && reason.response) {
          if (reason.response.status === 401) {
            toast.custom(() => (
              <AlertNotif
                title="Unauthenticated, Redirecting to website."
                type="error"
              />
            ));
            // redircetToLogin();
            return false;
          }
          if (reason.error) {
            const errorData = reason.response.data;
            // mToast.error({
            //   text: errorData.message
            //     ? errorData.message
            //     : "Something went wrong",
            // });
          }
          return { error: true, response: reason.response };
        }
      });
  };
  const getWalletBalance = () => {
    fetchPaymentMethod()
      .then((payload) => {
        if (isMounted && payload.success) {
          initWallet(payload.data[1]?.wallet);
        }
      })
      .catch((reason) => {
        if (isMounted && reason.response) {
          if (reason.response.status === 401) {
            toast.custom(() => (
              <AlertNotif
                title="Unauthenticated, Redirecting to website."
                type="error"
              />
            ));
            // redircetToLogin();
            return false;
          }
          const errorData = reason.response ? reason.response.data : {};
          // mToast.error({
          //   text: errorData.error
          //     ? errorData.error.message
          //     : "Something went wrong",
          // });
        }
      });
  };
  const initSocket = () => {
    socket.on("whoareyou", () => {
      socket.emit("auth", localStorage.getItem("token"));
    });
    socket.on("auth", (value) => initSocketStatus(value));
  };

  const initial = () => {
    if (window.location.pathname === "/login") return undefined;
    getAccount();
    getUnreadNotificationsCount();
    getWalletBalance();
    initSocket();
  };

  // eslint-disable-next-line
  useEffect(initial, [socket]);
  useEffect(() => {
    fetchCategories().then((res) => {
      if (res?.message === "success" && res.data.length)
        getCategoryPostJob(res.data);
    });
  }, []);

  // useEffect(() => {
  //   getAccount();
  // }, [account]);

  useEffect(() => {
    return history.listen(() => {
      getUnreadNotificationsCount();
      getWalletBalance();
      // window.analytics.page(window.location.pathname, {
      //   url: window.location.href,
      // });
    });
    // eslint-disable-next-line
  }, [history]);

  const href = window.location.href;

  const { REACT_APP_ANALYTICS_WRITE_KEY, NODE_ENV } = process.env;

  const renderSnippet = () => {
    var opts = {
      apiKey: REACT_APP_ANALYTICS_WRITE_KEY,
      page: true,
    };
    if (NODE_ENV === "development") {
      opts.load = { integrations: { All: false } };
      return snippet.max(opts);
    }
    return snippet.min(opts);
  };

  return (
    <>
      <Helmet>
        <script id="segment">{`${renderSnippet()}`}</script>
      </Helmet>
      {/* <Tips /> */}
      <Router history={history}>
        <MetaHeadEmbed
          render={(meta) => <head>{meta}</head>}
          siteTitle="Taskpin"
          pageTitle="Explore"
          titleTemplate="Taskpin Temp"
          description="Transforming challenges of all shapes and sizes into inventive, engaging and performance driven solutions that change the game."
          baseSiteUrl={href}
          pagePath="work"
          keywords={["creative-agency", "phantom", "work"]}
          imageUrl="https://dev.taskpin.ca/images/logo/logo128.png"
          imageAlt="PHANTOM logo."
        />
        <Layout>
          <Switch>
            {router.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            {process.env.NODE_ENV === "development" && (
              <Route
                path="/login"
                exact={false}
                component={(props) => <Signin open={true} {...props} />}
              />
            )}
            <Redirect to="/explore-tasks" />
          </Switch>
        </Layout>
      </Router>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
