import {
  Backdrop,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

const LoadingPages = (): JSX.Element => {
  const { palette } = useTheme();

  return (
    <Backdrop
      sx={{
        bgcolor: "white",
        zIndex: 900,
        position: { md: "absolute", xs: "" },
        borderRadius: "16px",
      }}
      open={true}
    >
      <Stack alignItems="center">
        <CircularProgress
          size={50}
          sx={{ color: palette.primary.main, fontSize: 20 }}
        />
        <Typography
          component="span"
          variant="subtitle1"
          sx={{ color: palette.neutral[900], mt: 2 }}
        >
          Loading...
        </Typography>
      </Stack>
    </Backdrop>
  );
};

export default LoadingPages;
