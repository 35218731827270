export enum Pages {
  Jobs = "Jobs",
  Filter = "Filter",
  Categories = "Categories",
  Sort = "Sort",
  JobType = "JobType",
  Suburb = "Suburb",
  JobDetails = "JobDetails",
  JobAlerts = "JobAlerts",
  AddJobAlert = "AddJobAlert",
  AlertSetting = "AlertSetting",
  AlertKeyWord = "AlertKeyWord",
  AlertTitle = "JobAlerts/components/Title",
  SettingKeyWord = "SettingKeyWord",
  SetJobAlert = "SetJobAlert",
  Message = "Message",
  OfferRequirements = "JobOffer/OfferRequirements",
  JobOfferOtp = "JobOffer/OtpVerification",
  JobOfferBankAccount = "JobOffer/BankAccount",
  JobOfferBillingAddress = "JobOffer/BillingAddress",
  JobOfferSuburb = "JobOffer/Suburb",
  MakeAnOffer = "JobOffer/MakeOffer",
  OfferJobDetails = "JobOffer/OfferJobDetails",
  JobOfferDetail = "JobOffer/Detail",
  JobOfferText = "JobOffer/OfferText",
  JobOfferReview = "JobOffer/Review",
  JobOfferView = "JobOffer/View",
  JobOfferProvince = "JobOffer/Province",
  ReportJob = "ReportJob",
  JobDetailsSkeleton = "JobDetails/components/JobDetailsSkeleton",
  Notifications = "NotificationMobile",
}
export interface Attachments {
  id: number;
  name: string;
  file_name: string;
  file_size: number;
  mime: string;
  url: string;
  thumb_url: string;
  modal_url: string;
  created_at: string;
}

export interface JobUser {
  id: number;
  slug: string;
  name: string;
  avatar: Attachments;
  last_online: string;
}
export interface BudgetPlan {
  id: number;
  min_budget: number;
  max_budget: number;
}
export interface Job {
  location?: string;
  longitude?: number;
  latitude?: number;
  title: string;
  description: string;
  task_type: string;
  status: string;
  flexible_time: boolean;
  due_date: string;
  due_time: { [key: string]: boolean };
  offers_count: number;
  budgetplan: BudgetPlan;
  min_budget?: number;
  max_budget?: number;
  budget: number;
  attachments?: Attachments[];
  slug: string;
  created_at: string;
  poster: JobUser;
  id: number;
  position?: any;
}
export interface JobDetails extends Job {
  offers: Offer[];
  budget_plan?: any;
  max_budget?: number;
  min_budget?: number;
  offer_count?: number;
  worker?: any;
}
export interface PageMetaType {
  pagesCount: number;
  from: number;
  to: number;
  total: number;
  current?: any;
}
export interface Filter {
  task_type: string;
  min_price: number;
  max_price: number;
  distance: number;
  location: string;
  current_lng?: string;
  current_lat?: string;
}

export interface SuburbType {
  Suburb: string;
  latitude: string;
  longitude: string;
}

export interface Offer {
  offer_price?: any;
  message: string;
  attachment?: any;
  worker?: any;
  created_at?: string;
}

export interface AccountInformation {
  id: number;
}
