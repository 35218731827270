import { styled } from "@mui/material/styles";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Container } from "@mui/material";
import { useLocation } from "react-router-dom";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.primary[50],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.primary.main,
  },
}));

interface ProgressProps {
  step: number;
}

export const Progress = ({ step }: ProgressProps) => {
  const location = useLocation();

  const calculateProgressValue = (step: number) => {
    switch (location.search) {
      case "?Title":
        return 20;
      case "?Location":
      case "Edit-address":
        return 40;
      case "?Edit-address":
        return 40;
      case "?Date":
      case "?Calender":
      case "?Time":
        return 60;
      case "?Budget":
        return 80;
      case "?Details":
      case "?Attachments":
        return 85;
      // case 7:
      //   return 90;
      case "?Summary":
        return 100;
    }
  };
  return (
    <Container
      sx={{
        mb: { xs: 3, md: 0 },
        mt: { xs: 3, md: "28px" },
      }}
    >
      <BorderLinearProgress
        sx={{
          height: { xs: "4px", md: "8px" },
          borderRadius: { xs: "2px", md: "4px" },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: { xs: "2px", md: "4px" },
          },
        }}
        variant="determinate"
        value={calculateProgressValue(step)}
      />
    </Container>
  );
};
