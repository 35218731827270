import { Avatar, Button, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { Icon } from "components/Icon";
import moment from "moment";
import parse from "html-react-parser";
import { dismissNotification, fetchNotifications } from "api/exploreJob";
import { useState } from "react";
import { toast } from "react-hot-toast";
import AlertNotif from "components/AlertNotif";
import LoadingPages from "components/LoadingPages";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import useAccountInfoStore from "store/zustand/accountInformation";
import useNotificationsCountStore from "store/zustand/notificationsCount";

interface NotificationCardProps {
  notif?: any;
  setNotification?: any;
  setNotificationEl?: any;
}

const NotificationCard = ({
  notif,
  setNotification,
  setNotificationEl,
}: NotificationCardProps): JSX.Element => {
  const content: any = `${notif?.data?.title}`;
  const history = useHistory();
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState<boolean>(false);
  const htmlObject: any = document.createElement("div");
  htmlObject.innerHTML = content;
  const { palette } = useTheme();
  const AccountInfo: any = useAccountInfoStore(
    (state) => state.AccountInformation
  );
  const setNotificationCount: any = useNotificationsCountStore(
    (state) => state.setNotificationUnread
  );

  const handleDismiss = () => {
    setLoading(true);
    dismissNotification({
      id: notif?.id,
    })
      .then((res) => {
        dismissNotification({
          id: notif?.id,
        });
        setNotificationCount("unread_count", res.data.unread_count);
        fetchNotifications(1).then((res) => {
          setNotification(res.data);
        });
      })
      .catch((err) => {
        toast.custom((t) => (
          <AlertNotif
            title={err?.response.data.error.message}
            type="error"
            target={t}
          />
        ));
      })
      .finally(() => setLoading(false));
  };

  const handleViewTask = (e: any, title: string) => {
    if (e.target.innerHTML === "View Message") {
      history.push(`/messaging/${notif?.data?.conversation?.id}`);
    } else if (title === "NewOffer") {
      dispatch({ type: "poster" });
      history.push(`/inventory/${notif?.data?.task_slug}?Offer#Offer-List`);
    } else if (
      AccountInfo?.id === notif.data.poster_id &&
      (notif.type === "TaskCancellationReject" ||
        notif.type === "TaskCancellationApprove" ||
        notif.type === "TaskCancellationRequest")
    ) {
      dispatch({ type: "poster" });
      history.push(`/inventory/${notif?.data?.task_slug}?Cancellation-Request`);
    } else if (
      AccountInfo?.id !== notif.data.poster_id &&
      (notif.type === "TaskCancellationReject" ||
        notif.type === "TaskCancellationApprove" ||
        notif.type === "TaskCancellationRequest")
    ) {
      dispatch({ type: "ticker" });
      history.push(`/workspace/${notif?.data?.task_slug}?Cancellation-Request`);
    } else {
      dispatch({ type: "ticker" });
      history.push(`/explore-tasks/${notif?.data?.task_slug}`);
    }
    setNotificationEl(null);
  };

  return (
    <Box sx={{ position: "relative" }}>
      {Loading ? <LoadingPages /> : null}
      <Box
        sx={{ py: 1, px: 2, borderBottom: "1px solid" + palette.neutral[100] }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "397px",
            mt: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Icon
              iconSet={
                notif.type === "NewOffer"
                  ? "Flag"
                  : notif.type === "OfferAccepted"
                  ? "CircleCheck"
                  : notif.type === "TaskRescheduleApproved" ||
                    notif.type === "TaskRescheduleReject" ||
                    notif.type === "TaskRescheduleRequest"
                  ? "History"
                  : notif.type === "AdditionalfundReject" ||
                    notif.type === "AdditionalfundRequest" ||
                    notif.type === "AdditionalfundApprove"
                  ? "Monetization"
                  : notif.type === "PaymentReject" ||
                    notif.type === "PaymentApprove" ||
                    notif.type === "PaymentRequest"
                  ? "CreditCard"
                  : notif.type === "TaskCancellationReject" ||
                    notif.type === "TaskCancellationApprove" ||
                    notif.type === "TaskCancellationRequest"
                  ? "Cancel"
                  : notif.type === "NewMessage"
                  ? "Chat"
                  : notif.type === "TaskAlert"
                  ? "CircleNotifications"
                  : "Work"
              }
              sx={{ color: palette.secondary.main, fontSize: "16px" }}
            />
            <Typography
              component="span"
              variant="label"
              sx={{ color: palette.secondary.main, ml: 1 }}
            >
              {notif?.data?.trigger === "task"
                ? notif?.title === "notification.title.TaskAlert"
                  ? "Task Alert"
                  : notif?.title
                : notif?.data?.trigger === "comment"
                ? "Review Job"
                : notif?.data?.trigger === "question"
                ? "New Message"
                : notif?.title}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography
              component="span"
              variant="label"
              sx={{ color: palette.neutral[500] }}
            >
              {moment(notif?.created_at).fromNow()}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 1,
            display: "flex",
            gap: 1.5,
            alignItems: "center",
          }}
        >
          <Avatar
            src={
              notif?.user?.avatar?.thumb_url
                ? notif?.user?.avatar?.thumb_url
                : "/images/Theme=Light, Size=32x32, State=Guest.svg"
            }
            sx={{
              width: "48px",
              height: "48px",
              overflow: "unset",
              img: { borderRadius: "100%" },
              bgcolor: palette.neutral[50],
              color: palette.neutral[300],
            }}
          />

          <Typography component="span" variant="caption">
            {parse(content)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
          }}
        >
          <Box>
            <Button
              sx={{
                color: palette.neutral[500],
                "&:hover": { backgroundColor: "unset" },
                height: { md: 35, xs: 40 },
              }}
              onClick={handleDismiss}
            >
              <Typography
                component="span"
                variant="caption"
                sx={{ color: palette.neutral.main }}
              >
                Dismiss
              </Typography>
            </Button>
            <Button
              sx={{
                color: palette.neutral[500],
                "&:hover": { backgroundColor: "unset" },
                height: { md: 35, xs: 40 },
              }}
              onClick={(e) => handleViewTask(e, notif.type)}
            >
              <Typography
                component="span"
                variant="caption"
                sx={{ color: palette.primary.main }}
              >
                {notif?.data?.trigger === "task"
                  ? "View Details"
                  : notif?.data?.trigger === "comment"
                  ? "Write a Review"
                  : notif?.data?.trigger === "conversation"
                  ? "View Message"
                  : "Review Comment"}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationCard;
