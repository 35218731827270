export function notificationReducer(state = null, action) {
    switch (action.type) {
        case 'INIT_NOTIFICATIONS_COUNT':
            return Object.assign({}, state, action.payload);
        case 'UPDATE_NOTIFICATIONS_COUNT':
            return Object.assign({}, state, action.payload);
        default:
            return state;

    }
}