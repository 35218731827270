export function walletReducer(state = null, action) {
    switch (action.type) {
        case 'INIT_WALLET':
            return Object.assign({}, state, action.payload);
        case 'UPDATE_WALLET':
            return Object.assign({}, state, action.payload);
        default:
            return state;

    }
}