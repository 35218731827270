import axios from "axios";

const access_token = `pk.eyJ1IjoidGFza3BpbiIsImEiOiJjbGpkN3o1MnQycmgzM2ZxbjU5eG81Y2tiIn0.wc_JnSfpWOKj7ymfu4WVtQ`;
export function getLocations(searchText: string) {
  return axios
    .get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json?country=ca&limit=10&types=address&access_token=${access_token}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function getLocationsByPostCode(searchText: string) {
  return axios
    .get(
      `https://api.mapbox.com/search/geocode/v6/forward?q=${searchText}&country=ca&proximity=ip&access_token=${access_token}`
    )

    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function getNeighborhoodLocations(searchText: string) {
  return axios
    .get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json?country=ca&limit=10&types=neighborhood&access_token=${access_token}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function getFakeLocation(latLng: any) {
  return axios
    .get(
      `https://api.mapbox.com/v4/mapbox.mapbox-streets-v8/tilequery/${latLng}.json?radius=1000&limit=50&dedupe&access_token=${access_token}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
