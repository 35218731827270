import { Box, Button, Typography, useTheme } from "@mui/material";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

interface ItemProps {
  title: string;
  value: string;
  disable?: boolean;
  onClick?: any;
}

export const Item = ({
  title,
  value,
  disable,
  onClick,
}: ItemProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        py: "12px",
      }}
      onClick={onClick}
    >
      <Typography variant="subtitle2" sx={{ color: palette.neutral[700] }}>
        {title}
      </Typography>
      <Button
        disabled={disable}
        sx={{
          p: 0 + "!important",
          minWidth: "auto",
          display: "flex",
          alignItems: "center",
          "&::hover .icon": {
            backgroundColor: palette.primary.main,
          },
          "&::focus .icon": {
            outline: "none",
          },
          "&:hover": {
            backgroundColor: palette.neutral.min,
          },
          // height: { md: 35, xs: 40 },
        }}
      >
        <Typography variant="body2" sx={{ color: palette.neutral[500] }}>
          {value}
        </Typography>
        <ArrowForwardIosRoundedIcon
          sx={{
            width: "14px",
            height: "24px",
            ml: 1,
            color: palette.neutral[500],
            display: disable ? "none" : "",
          }}
        />
      </Button>
    </Box>
  );
};
