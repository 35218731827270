import API, { APIV2, APIV3 } from "./_config";

export function fetchJobs(mode, searchFor, status, filter, pageNumber) {
  switch (mode) {
    case "my-jobs":
      return APIV2.get(`my-jobs`, {
        params: {
          search_query: searchFor,
          status: status,
          page: pageNumber,
        },
      })
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          throw e;
        });
    case "saved-jobs":
      return API.get(`bookmarks`, {
        params: {
          search_query: searchFor,
          mytask: status,
          page: pageNumber,
        },
      })
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          throw e;
        });
    default:
      return APIV2.get(`jobs`, {
        params: {
          search_query: searchFor,
          ...filter,
          distance: filter.task_type === "remote" ? undefined : filter.distance,
          location: undefined,
          current_lat:
            filter.task_type === "remote" || !filter.distance
              ? undefined
              : filter.current_lat,
          current_lng:
            filter.task_type === "remote" || !filter.distance
              ? undefined
              : filter.current_lng,
          page: pageNumber,
        },
      })
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          throw e;
        });
  }
}

export function fetchJob(slug) {
  return API.get(`tasks/${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function deleteJob(slug) {
  return API.delete(`tasks/${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function fetchCategories() {
  return APIV3.get(`category`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function submitJob(data) {
  return API.post(`tasks/create`, {
    ...data,
    payment_type: data.payment_type || "fixed",
    task_type: data.type || data.location ? "physical" : "remote",
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function uploadTemporaryAttachment(file) {
  let formData = new FormData();
  formData.append("media", file, file.fileName);
  return API.post(`/media/temp-attachment`, formData, {
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function editJob(taskSlug, data) {
  return API.put(`tasks/${taskSlug}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function addJobAttachment(taskSlug, file) {
  let formData = new FormData();
  formData.append("media", file, file.fileName);
  return API.post(`/tasks/${taskSlug}/attachment`, formData, {
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteJobAttachment(taskSlug, mediaId) {
  return API.delete(`/tasks/${taskSlug}/attachment?media=${mediaId}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function bookmarkJob(taskSlug, data) {
  return API.post(`tasks/${taskSlug}/bookmark`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteBookmarkJob(bookmarkId) {
  return API.delete(`bookmarks/${bookmarkId}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function cancellJob(taskSlug, data) {
  return API.post(`tasks/${taskSlug}/cancellation`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchCancellationReasons() {
  return API.get(`cancellation/reasons`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchCancellationFee() {
  return API.get(`cancellation/notice`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteCancellation(cancellationId) {
  return API.delete(`cancellation/${cancellationId}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function declineCancellation(cancellationId, data) {
  return API.post(`cancellation/${cancellationId}/decline`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function acceptCancellation(cancellationId) {
  return API.get(`cancellation/${cancellationId}/accept`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function fetchQuestions(taskId, pageNumber) {
  return API.get(`/tasks/${taskId}/questions?page=${pageNumber}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function createQuestion(taskId, data) {
  return API.post(`questions/${taskId}/create`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function replyQuestion(questionId, data) {
  return API.post(`questions/${questionId}/comments`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function mediaUpload(file) {
  let formData = new FormData();
  formData.append("media", file, file.fileName);
  return API.post(`media/temp-attachment`, formData, {
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function submitOffer(data) {
  return API.post(`offers`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchEarningOfferCalculation(amount) {
  return API.post(`offers/earning_calculation`, {
    amount,
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchPayingOfferCalculation(amount) {
  return API.post(`offers/paying_calculation`, {
    amount,
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteOffer(offerId) {
  return API.delete(`offers/${offerId}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function replyOffer(offerId, data) {
  return API.post(`offers/${offerId}/comments`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function acceptOffer(taskSlug, offer_id, discount_code) {
  return API.post(`tasks/${taskSlug}/accept-offer`, {
    offer_id,
    discount_code,
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function submitReport(mode, id, data) {
  let url = `tasks/${id}/report`;
  switch (mode) {
    case "account":
      url = `profile/${id}/report`;
      break;
    case "offer":
      url = `offers/${id}/report`;
      break;
    case "question":
      url = `querstions/${id}/report`;
      break;
    case "comment":
      url = `comments/${id}/report`;
      break;
    default:
  }
  return API.post(url, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchJobAlerts() {
  return APIV2.get(`jobalerts`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function createJobAlert(data) {
  return APIV2.post(`jobalerts`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteJobAlert(taskAlertId) {
  return APIV2.delete(`jobalerts/${taskAlertId}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function updateJobAlertStatus(status) {
  return APIV2.post(`jobalerts/status`, { status })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchJobAlertStatus() {
  return APIV2.get(`jobalerts/status`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchStatistics() {
  return API.get(`statistics/tasks`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function submitRescheduleJob(taskSlug, data) {
  return API.post(`tasks/${taskSlug}/reschedule`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteRescheduleJob(id) {
  return API.delete(`reschedule/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function declineRescheduleJob(id, data) {
  return API.post(`reschedule/${id}/reject`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function acceptRescheduleJob(id) {
  return API.get(`reschedule/${id}/accept`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function submitIncreasePrice(taskSlug, data) {
  return API.post(`tasks/${taskSlug}/additionalfund/request`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteIncreasePrice(id) {
  return API.delete(`additionalfund/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function declineIncreasePrice(id, data) {
  return API.post(`additionalfund/${id}/reject`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function acceptIncreasePrice(id) {
  return API.get(`additionalfund/${id}/accept`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function requestReleasePayment(taskSlug) {
  return API.post(`tasks/${taskSlug}/complete`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function acceptReleasePayment(taskSlug) {
  return API.post(`tasks/${taskSlug}/close`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function rejectReleasePayment(taskSlug) {
  return API.post(`tasks/${taskSlug}/reject_complete_request`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function submitRating(taskId, rateBy, data) {
  return API.post(`tasks/${taskId}/rating/submit-review`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

/*
params:{
  limit: number
  page: number
  task_status: close|open|completed|assigned
  user_type: poster|worker
}
*/
export function fetchJobMonthlyStats(params) {
  return API.get(`/task_stats/year_month_count`, {
    params,
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchJobInvoice(taskSlug) {
  return API.get(`tasks/${taskSlug}/invoice`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function submitJobEvent(data) {
  return APIV2.post(`event`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
