import { getNotificationCountBadge } from "api/badge";
import { createContext, ReactNode, useState } from "react";
import { useQuery } from "react-query";

interface LayoutContextType {
  MessageBadgeCount?: number;
  setMessageBadgeCount: Function;
}
const LayoutContext = createContext<LayoutContextType>({
  setMessageBadgeCount(): void {},
});

export default LayoutContext;

interface LayoutContextProviderType {
  children: ReactNode;
}

export const LayoutContextprovider = ({
  children,
}: LayoutContextProviderType): JSX.Element => {
  const [MessageBadgeCount, setMessageBadgeCount] = useState();

  useQuery(
    "getNotificationCountBadge",
    () => {
      return getNotificationCountBadge();
    },
    {
      onSuccess: (info) => {
        setMessageBadgeCount(info.data.conversation);
      },
      onError: (error) => {
        console.log(error);
      },
      refetchInterval: 60000,
    }
  );

  return (
    <LayoutContext.Provider
      value={{
        MessageBadgeCount,
        setMessageBadgeCount,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
