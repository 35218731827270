export function modeReducer (state = { mode: "ticker" }, action) {
    switch (action.type) {
        case 'poster':
            return { mode: "poster" };
        case 'ticker':
            return { mode: "ticker" };
        default:
            return state;

    }
}