import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import { Step } from "../Step";
import { Icon } from "../../../../components/Icon";
import { mediaUpload } from "api/postJob/postJob";
import AlertNotif from "components/AlertNotif";
import { toast } from "react-hot-toast";
interface AttachmentsPropsType {
  setTaskData: Dispatch<SetStateAction<{ [key: string]: any }>>;
  taskData: {
    [key: string]: any;
  };
}

export const Attachments = ({
  setTaskData,
  taskData,
}: AttachmentsPropsType): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [uploadAgain, setUploadAgain] = useState<boolean>(false);
  const [fileId, setFileId] = useState<number>(-1);
  const { palette } = useTheme();
  const [openImageUrl, setOpenImageUrl] = useState<string>("");
  const [image, setImage] = useState<any>(null);
  const [attachments, setAttachments] = useState<any>(
    taskData.files ? taskData.files : []
  );
  const under360 = useMediaQuery("(max-width:360px)");
  const over471 = useMediaQuery("(min-width:472px) and (max-width:900px)");
  // const [imageURL, setImageURL] = useState<any>([]);
  const [showImageDialog, setShowImageDialog] = useState<boolean>(false);
  const handleClose: any = () => {
    setShowImageDialog(false);
  };
  // const copyJob = Cookies.get("copyJob");

  useEffect(() => {
    if (attachments) {
      setTaskData((data) => ({
        ...data,
        files: data?.files?.map((file: { id: any }) =>
          typeof file === "object" ? file.id : file
        ),
        fileUrl:
          data?.files?.length > 0 && typeof data?.files?.[0] === "object"
            ? data?.files?.map((file: any) => file)
            : data?.fileUrl,
      }));
    } else {
      return;
    }
  }, []);
  useEffect(() => {
    if (image) {
      for (let i = 0; i < image.length; i++) {
        let formData = new FormData();
        formData.append("media", image[i]);
        setLoading(true);
        mediaUpload(formData)
          .then((res) => {
            setTaskData((current) => ({
              ...current,
              files: [...(current.files ?? []), res?.data?.id],
              fileUrl: [...(current.fileUrl ?? []), res?.data],
            }));

            setAttachments((prevCompanies: any) => {
              return [...prevCompanies, res?.data];
            });
          })
          .catch((err) => {
            toast.custom((t) => (
              <AlertNotif
                title={err?.response.data.error.message}
                type="error"
                target={t}
              />
            ));
          })
          .finally(() => setLoading(false));
      }
    }
  }, [image]);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setImage(acceptedFiles[0]);
    },
    noClick: taskData.files?.length >= 8,
    accept: [".pdf", ".jpeg", ".jpg", ".png"],
  });

  const handleMouseOver = (event: MouseEvent<HTMLDivElement>) => {
    if (event.currentTarget.children[1]) {
      (event.currentTarget.children[1] as any).style.display = "flex";
      (event.currentTarget.children[2] as any).style.display = "flex";
    }
  };

  const handleMouseLeave = (event: MouseEvent<HTMLDivElement>) => {
    if (event.currentTarget.children[1]) {
      (event.currentTarget.children[1] as any).style.display = "none";
      (event.currentTarget.children[2] as any).style.display = "none";
    }
  };

  const handleDelete = useCallback(() => {
    setTaskData((current) => ({
      ...current,
      files: (current.files as any[])?.filter((_, i) => i !== fileId),
      fileUrl: (current.fileUrl as any[])?.filter((_, i) => i !== fileId),
    }));
    setAttachments((current: any) =>
      current?.filter((_: any, i: number) => i !== fileId)
    );
    setDeleteModal(false);
  }, [fileId, setTaskData]);

  const handleOpenDeleteModal = (
    e: MouseEvent<HTMLDivElement>,
    index: number
  ) => {
    setDeleteModal(true);
    setFileId(index);
  };

  const handleCloseModal = () => {
    setFileId(-1);
    setDeleteModal(false);
  };

  const handleUploadAgain = () => {
    open();
    setUploadAgain(false);
  };

  return (
    <Step title="Attachments">
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: "558px" }}>
          <Typography variant="h5">Add images and docs</Typography>
          <Box
            sx={{
              mt: { xs: 3, md: 2 },
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              justifyContent: "center",
            }}
          >
            <Box>
              <input
                {...getInputProps()}
                onChange={(e: any) => {
                  e.persist();
                  if (
                    e?.target?.files[0]?.type?.startsWith("image") ||
                    e?.target?.files[0]?.type === "application/pdf"
                  )
                    setImage(e?.target?.files);
                  else
                    toast.custom((t) => (
                      <AlertNotif
                        title="You can only upload images and PDFs"
                        type="primary"
                        target={t}
                      />
                    ));
                }}
                accept=".pdf, .jpeg, .jpg, .png"
                disabled={loading}
              />
              <Box
                {...getRootProps()}
                sx={{
                  width: { xs: "98px", sm: over471 ? "558px" : "508px" },
                  height: { xs: "98px", md: "64px" },
                  border: `1px dashed ${palette.neutral[400]}`,
                  borderRadius: under360 ? "8px" : "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "&:hover": { cursor: loading ? "default" : "pointer" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 4,
                    color: loading
                      ? palette.neutral[400]
                      : palette.primary.main,
                  }}
                >
                  <AddRoundedIcon
                    sx={{
                      display: { xs: "flex", md: "none" },
                      fontSize: "48px",
                    }}
                  />
                  {/* <CloudUploadRoundedIcon
                    sx={{
                      mr: { xs: 0, md: "13px" },
                      display: { xs: "none", md: "flex" },
                    }}
                  /> */}
                  <Icon
                    iconSet="File-upload"
                    sx={{
                      mr: { xs: 0, md: "10px" },
                      display: { xs: "none", md: "flex" },
                    }}
                  />
                  <Typography
                    variant="body1"
                    sx={{ display: { xs: "none", sm: "flex" } }}
                  >
                    {over471
                      ? "Browse your files"
                      : "Drag & drop or browse your files"}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {[0, 1, 2, 3, 4, 5, 6, 7].map((i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    width: { xs: "98px", md: "127px" },
                    height: { xs: "98px", md: "96px" },
                    backgroundColor: palette.neutral[50],
                    border: `1px solid ${palette.neutral[100]}`,
                    borderRadius: under360 ? "8px" : "4px",
                  }}
                  onMouseOver={handleMouseOver}
                  onMouseLeave={handleMouseLeave}
                >
                  {taskData?.fileUrl?.[i] && (
                    <>
                      {taskData?.fileUrl?.[i]?.mime?.includes("image") && (
                        <Box
                          sx={{
                            border: "1px solid " + palette.neutral[100],
                            display: "inline-block",
                            width: { xs: "98px", md: "127px" },
                            height: { xs: "98px", md: "96px" },
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={taskData?.fileUrl?.[i]?.url}
                            alt=""
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              height: "100%",
                              // borderRadius: "4px",
                              // width: "100%",
                              // height: "100%",
                            }}
                          />
                        </Box>
                      )}

                      {error && (
                        <ErrorOutlineRoundedIcon
                          sx={{
                            width: "27px",
                            height: "27px",
                            color: palette.error.main,
                          }}
                        />
                      )}
                      {taskData?.fileUrl?.[i]?.mime?.includes("pdf") && (
                        <PictureAsPdfOutlinedIcon
                          sx={{
                            width: "27px",
                            height: "27px",
                            color: palette.primary.main,
                          }}
                        />
                      )}
                      <Box
                        sx={{
                          display: "none",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          top: 0,
                          right: 0,
                          backgroundColor: palette.neutral[200],
                          width: "24px",
                          height: "24px",
                          borderBottomLeftRadius: "4px",
                          borderTopRightRadius: "3px",
                          cursor: "pointer",
                          mt: "-1px",
                        }}
                        onClick={(e) => handleOpenDeleteModal(e, i)}
                      >
                        <CloseRoundedIcon
                          sx={{
                            color: palette.neutral[500],
                            width: "18px",
                            height: "18px",
                          }}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "none",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          left: 0,
                          backgroundColor: palette.neutral[200],
                          height: "24px",
                          borderBottomLeftRadius: "3px",
                          borderBottomRightRadius: "3px",
                          cursor: "pointer",
                          mb: "-1px",
                        }}
                        onClick={(e: any) => {
                          setShowImageDialog(true);
                          setOpenImageUrl(
                            e.target.parentElement.parentElement.children[0]
                              .children[0].src
                          );
                        }}
                      >
                        <Typography
                          component={
                            taskData?.fileUrl?.[i]?.mime?.includes("pdf")
                              ? "a"
                              : "span"
                          }
                          variant="caption"
                          sx={{
                            color: palette.neutral[600],
                          }}
                          href={
                            taskData?.fileUrl?.[i]?.mime?.includes("pdf")
                              ? taskData?.fileUrl?.[i]?.url
                              : undefined
                          }
                          target="_blank"
                        >
                          Click to View
                        </Typography>
                      </Box>
                      {openImageUrl ? (
                        // <Dialog
                        //   open={showImageDialog}
                        //   onClose={handleClose}
                        //   aria-labelledby="alert-dialog-title"
                        //   aria-describedby="alert-dialog-description"
                        //   sx={{ ".MuiDialog-paper": { borderRadius: "8px" } }}
                        //   PaperProps={{
                        //     sx: {
                        //       maxHeight: "90%",
                        //       // display: "inline-block",
                        //       // overflow: "hidden",
                        //       maxWidth: "90%",
                        //     },
                        //   }}
                        // >
                        //   <Button
                        //     sx={{
                        //       // position: "absolute",
                        //       right: 0,
                        //       color: palette.neutral[900],
                        //       justifyContent: "end !important",
                        //     }}
                        //     onClick={() => {
                        //       setShowImageDialog(false);
                        //     }}
                        //   >
                        //     <Icon iconName="close" />
                        //   </Button>
                        //   <Box
                        //     component="img"
                        //     src={openImageUrl}
                        //     alt={"atach"}
                        //     sx={{
                        //       objectFit: "cover",
                        //       width: "100%",
                        //       height: 750,
                        //     }}
                        //   />
                        // </Dialog>
                        <Dialog
                          open={showImageDialog}
                          onClose={handleClose}
                          //  hasCloseButton
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        >
                          <Button
                            sx={{
                              // position: "absolute",
                              // right: 0,
                              color: palette.neutral[900],
                              justifyContent: "end !important",
                            }}
                            onClick={() => {
                              setShowImageDialog(false);
                            }}
                          >
                            <Icon iconSet="Close" />
                          </Button>
                          <Box
                            component="img"
                            sx={{
                              maxWidth: "100%",
                              maxHeight: "calc(95vh - 64px)",
                            }}
                            src={openImageUrl}
                            alt="image"
                          />
                        </Dialog>
                      ) : null}
                    </>
                  )}
                  {loading &&
                  taskData.fileUrl?.length === undefined &&
                  i === 0 ? (
                    <CircularProgress />
                  ) : (
                    loading &&
                    taskData.fileUrl?.length === i && <CircularProgress />
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Modal open={deleteModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "312px", md: "558px" },
            bgcolor: palette.neutral.min,
            boxShadow: " 0px 2px 8px rgba(0, 0, 0, 0.25)",
            borderRadius: "8px",
            px: 4,
          }}
        >
          <Typography variant="h5" sx={{ color: palette.neutral[900], mt: 3 }}>
            Delete this item?
          </Typography>
          <Typography variant="body1" sx={{ color: palette.neutral[600] }}>
            Are you sure you want to delete this item from your attachments?
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              mt: 3,
              mb: 2,
              height: { xs: "34px", md: "auto" },
            }}
          >
            <Button
              sx={{ color: palette.primary.main, height: { md: 35, xs: 40 } }}
              onClick={handleCloseModal}
            >
              <Typography variant="button">Cancel</Typography>
            </Button>
            <Divider
              orientation="vertical"
              sx={{ height: "100%", display: { xs: "flex", md: "none" } }}
            />
            <Button
              sx={{ color: palette.error.main, height: { md: 35, xs: 40 } }}
              onClick={handleDelete}
            >
              <Typography variant="button">Delete</Typography>
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={uploadAgain} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "325px", md: "558px" },
            bgcolor: palette.neutral.min,
            boxShadow: " 0px 2px 8px rgba(0, 0, 0, 0.25)",
            borderRadius: "8px",
            px: 4,
          }}
        >
          <Typography variant="h5" sx={{ color: palette.neutral[900], mt: 3 }}>
            Upload this item again?
          </Typography>
          <Typography variant="body1" sx={{ color: palette.neutral[600] }}>
            Would you like to try uploading this item again?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "end", mt: 3, mb: 2 }}>
            <Button
              sx={{ color: palette.error.main, height: { md: 35, xs: 40 } }}
              onClick={handleDelete}
            >
              <Typography variant="button">Delete</Typography>
            </Button>
            <Divider />
            <Button
              sx={{ color: palette.primary.main, height: { md: 35, xs: 40 } }}
              onClick={handleUploadAgain}
            >
              <Typography variant="button">Upload again</Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </Step>
  );
};
