import React from "react";
import { connect } from "react-redux";
import AppLayout from "./App";
import {LayoutContextprovider} from "./App/components/Context";

function Layout({ children, account }) {
  return (  
    <LayoutContextprovider>{
      <AppLayout account={account}>
        {account || window.location.pathname === "/login" ? (
          children
          ) : (
            // <LocalLoadingPages />
            <></>
            )}
      </AppLayout>
    }</LayoutContextprovider>
  );
}

function mapStateToProps(state) {
  return {
    account: state.account ? state.account : false,
  };
}

export default connect(mapStateToProps, null)(Layout);
