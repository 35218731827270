import { SetStateAction, useCallback, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
  keyframes,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

import { Step } from "../Step";
import {
  FieldErrorsImpl,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormTrigger,
} from "react-hook-form";
import { Icon } from "../../../../components/Icon";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { callAI } from "api/postJob/postJob";
interface DetailsPropsType {
  errors?: FieldErrorsImpl<{
    [x: string]: any;
  }>;
  register: UseFormRegister<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  handleStep: (value: SetStateAction<number>) => void;

  setTaskData?: any;
  taskData?: any;
  descriptionAI?: string;
}

export const Details = ({
  register,
  errors,
  clearErrors,
  setTaskData,
  trigger,
  handleStep,
  taskData,
  descriptionAI,
}: DetailsPropsType): JSX.Element => {
  const [focused, setFocused] = useState<boolean>(false);
  const { palette, breakpoints } = useTheme();
  const myEffect = keyframes`
  0% {
    transform: rotate(0deg);

  }
  100% {
    transform: rotate(360deg);
  }
`;
  const isUpMd = useMediaQuery(breakpoints.up("md"));
  const history = useHistory();
  const [AILoading, setAILoading] = useState<boolean>(false);
  const [AIHover, setAIHover] = useState<boolean>(false);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  const handleAIMouseLeave = () => {
    if (!AILoading) setAIHover(false);
  };
  const handleAIMouseOver = () => {
    if (!AILoading) setAIHover(true);
  };

  const handleAIGenerate = () => {
    if (descriptionAI) {
      setTaskData((current: any) => ({
        ...current,
        details: descriptionAI,
      }));
    } else {
      setAILoading(true);
      toast.promise(
        callAI(taskData.title).then((r: any) => {
          setTaskData((current: any) => ({
            ...current,
            details: r.data.description,
          }));
          setFocused(true);
          setAILoading(false);
        }),
        {
          loading: "Generating...",
          success: (res) => ``,
          error: (err) => ``,
        },
        {
          style: {
            backgroundColor: palette.primary[50],
            borderBottom: `2px solid ${palette.primary.main}`,
          },
          loading: {
            icon: (
              <Icon
                iconSet="Cached"
                sx={{
                  color: palette.primary.main,
                  animation: `${myEffect} 1s normal infinite`,
                }}
              />
            ),
          },
          success: {
            style: { display: "none" },
          },
          error: {
            style: { display: "none" },
          },
        }
      );
    }
  };

  return (
    <Step title="Details">
      <Box sx={{ width: { xs: "100%", sm: "558px" }, m: "0 auto" }}>
        <Typography
          variant="h5"
          sx={{
            color: palette.neutral.max,
          }}
        >
          What are the details of your task?
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "space-between" },
          }}
        >
          <Box
            sx={{
              flexDirection: "column",
              width: "100%",
            }}
          >
            <TextField
              fullWidth
              placeholder="Write a brief description of the key details of your task. Don’t worry. You can edit it later.
              "
              label="Details"
              disabled={AILoading}
              multiline
              rows={6}
              value={taskData.details || ""}
              sx={{
                width: { xs: "100%", md: "558px" },
                mt: "18px",
                p: 0,
                height: "163px",
                color: palette.neutral[900],
                "& fieldset": {
                  border: `1px solid ${palette.neutral[100]}`,
                  color: palette.neutral[400],
                  transition: "border 0.3s 0.1s",
                },
              }}
              onFocus={handleFocus}
              {...register("details", {
                minLength: {
                  value: 25,
                  message: "Must be at least 25 characters",
                },
                maxLength: {
                  value: 1000,
                  message: "Maximum 1000 characters are allowed",
                },
                onBlur: handleBlur,
                required: true,
                onChange: (e) => {
                  e.persist();
                  // trigger("details");
                  setTaskData((current: any) => ({
                    ...current,
                    details: e.target.value,
                  }));
                  clearErrors("details");
                },
              })}
              helperText={
                errors?.details?.message && (
                  <Typography
                    component="span"
                    variant="label"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: palette.error.main,
                    }}
                  >
                    <>
                      <ErrorRoundedIcon sx={{ mr: 0.5, fontSize: "16px" }} />
                      {errors.details.message}
                    </>
                  </Typography>
                )
              }
              FormHelperTextProps={{ sx: { mx: 0 } }}
              error={Boolean(errors?.details?.message)}
              InputProps={{
                endAdornment: isUpMd ? (
                  <Box
                    sx={{
                      height: "100%",
                      display: taskData.details ? "none" : "flex",
                      alignItems: "end",
                      justifyContent: "end",
                    }}
                  >
                    <IconButton
                      onMouseOver={handleAIMouseOver}
                      onMouseLeave={handleAIMouseLeave}
                      sx={{
                        py: "8px",
                        px: "16px !important",
                        borderRadius: "4px",
                        height: "35px",
                        bgcolor: AILoading
                          ? palette.neutral[100]
                          : palette.secondary[50],
                        color: palette.secondary.main,
                        alignItems: "center",
                        display: "flex",
                        overflow: "hidden",
                        "&:hover": {
                          bgcolor: !AILoading
                            ? palette.secondary[50]
                            : palette.neutral[100],
                          boxShadow: " 0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                        },
                      }}
                      onClick={handleAIGenerate}
                    >
                      {AILoading ? (
                        <CircularProgress
                          size={"19px"}
                          sx={{
                            color: palette.secondary.main,
                            mr: "4px",
                          }}
                        />
                      ) : (
                        <Icon iconSet="Wand" sx={{ mr: "4px" }} />
                      )}
                      {AILoading ? (
                        <Typography
                          variant="button"
                          sx={{
                            width: "84px",
                            color: palette.neutral[400],
                          }}
                        >
                          Generating...
                        </Typography>
                      ) : (
                        <Typography
                          variant="button"
                          sx={{
                            width: "112px",
                          }}
                        >
                          Generate with AI
                        </Typography>
                      )}
                    </IconButton>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      display: taskData.details ? "none" : "flex",
                      alignItems: "end",
                      justifyContent: "end",
                    }}
                  >
                    <IconButton
                      onMouseOver={handleAIMouseOver}
                      onMouseLeave={handleAIMouseLeave}
                      sx={{
                        py: "8px",
                        px: "16px !important",
                        borderRadius: "4px",
                        width: AILoading ? "139px" : "190px",
                        height: "35px",
                        bgcolor: AILoading
                          ? palette.neutral[100]
                          : palette.secondary[50],
                        color: palette.secondary.main,

                        display: "flex",
                        overflow: "hidden",
                        "&:hover": {
                          bgcolor: !AILoading
                            ? palette.secondary[50]
                            : palette.neutral[100],
                          boxShadow: " 0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                        },
                        position: "absolute",
                      }}
                      onClick={handleAIGenerate}
                    >
                      {AILoading ? (
                        <CircularProgress
                          size={"19px"}
                          sx={{
                            color: palette.secondary.main,
                            mr: "4px",
                          }}
                        />
                      ) : (
                        <Icon iconSet="Wand" sx={{ mr: "4px" }} />
                      )}
                      {AILoading ? (
                        <Typography
                          variant="button"
                          sx={{
                            width: "84px",
                            color: palette.neutral[400],
                          }}
                        >
                          Generating...
                        </Typography>
                      ) : (
                        <Typography variant="button">
                          Generate with AI
                        </Typography>
                      )}
                    </IconButton>
                  </Box>
                ),
              }}
            />
            {/* <Box
              sx={{
                mt: { xs: 4, md: 6.5 },
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => handleStep(7)}
            >
              <Typography
                variant="body1"
                sx={{
                  color: palette.neutral[900],
                }}
              >
                Attachments
              </Typography>
              <ChevronRightRoundedIcon
                sx={{
                  color: palette.neutral[600],
                }}
              />
            </Box> */}
            {!isUpMd ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 1,
                    px: 2,
                    width: "100%",
                    borderRadius: "8px",
                    backgroundColor: palette.neutral[50],
                    mt: 7,
                  }}
                >
                  <TipsAndUpdatesOutlinedIcon
                    sx={{
                      color: palette.primary.main,
                      width: "30px",
                      height: "30px",
                      mr: 1,
                    }}
                  />
                  <Typography variant="caption">
                    This will help us to give you more accurate offers and fewer
                    communications.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 1,
                    px: 2,
                    width: "100%",
                    borderRadius: "8px",
                    backgroundColor: palette.neutral[50],
                    mt: 2,
                  }}
                >
                  <Icon
                    iconSet="Wand"
                    sx={{
                      fontSize: "30px",
                      mr: 1,
                      color: palette.primary.main,
                    }}
                  />
                  <Typography variant="caption">
                    Taskpin AI can write your task details based on your entered
                    title.
                  </Typography>
                </Box>
              </>
            ) : null}
            <Box
              sx={{
                mt: { md: "40px", xs: "24px" },
              }}
            >
              <Button
                onClick={() => history.push({ search: "Attachments" })}
                type="submit"
                sx={{
                  color: palette.primary.main,
                  backgroundColor: palette.primary[50],
                  "&:hover": {
                    backgroundColor: "#E3E5FF",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                  },
                  py: "8px !important",
                  px: "16px !important",
                  "&.Mui-disabled": {
                    color: palette.neutral[400],
                    bgcolor: palette.neutral[100],
                  },
                  height: { md: 35, xs: 40 },
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Icon
                    iconSet="Attach-a-file"
                    sx={{ fontSize: "20px !important", mr: 1 }}
                  />
                  <Typography variant="button">
                    {taskData?.fileUrl?.length > 0
                      ? "Edit Attachments"
                      : "Add Attachments"}
                  </Typography>
                </Box>
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" }, ml: 8.5 }}>
            <Box
              sx={{
                display: "block",
                alignItems: "center",
                position: "absolute",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "48px",
                  height: "48px",
                  borderRadius: "8px",
                  backgroundColor: palette.primary[50],
                  mt: 2.3,
                  mb: { md: "6px" },
                  mr: { md: "", xs: 1 },
                }}
              >
                <TipsAndUpdatesOutlinedIcon
                  sx={{
                    color: palette.primary.main,
                    width: "30px",
                    height: "30px",
                  }}
                />
              </Box>
              <Box sx={{ width: { lg: "227px", md: "120px" } }}>
                <Typography variant="caption">
                  This will help us to give you more accurate offers and fewer
                  communications.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "block",
                alignItems: "center",
                position: "absolute",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "48px",
                  height: "48px",
                  borderRadius: "8px",
                  backgroundColor: palette.primary[50],
                  mt: 20,
                  mb: { md: "6px" },
                  mr: { md: "", xs: 1 },
                  color: palette.primary.main,
                }}
              >
                <Icon iconSet="Wand" sx={{ fontSize: "30px" }} />
              </Box>
              <Box sx={{ width: { lg: "227px", md: "120px" } }}>
                <Typography variant="caption">
                  Taskpin AI can write your task details based on your entered
                  title.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "block",
                alignItems: "center",
                position: "absolute",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "48px",
                  height: "48px",
                  borderRadius: "8px",
                  backgroundColor: palette.primary[50],
                  mt: 20,
                  mb: { md: "6px" },
                  mr: { md: "", xs: 1 },
                  color: palette.primary.main,
                }}
              >
                <Icon iconSet="Wand" sx={{ fontSize: "30px" }} />
              </Box>
              <Box sx={{ width: { lg: "227px", md: "120px" } }}>
                <Typography variant="caption">
                  Taskpin AI can write your task details based on your entered
                  title.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Step>
  );
};
