import { ReactNode } from "react";
import {
  Box,
  Container,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";

interface StepProps {
  title: string;
  children: ReactNode;
  sx?: SxProps<Theme>;
}

export const Step = ({ title, children, sx }: StepProps): JSX.Element => {
  const { palette } = useTheme();
  return (
    <Container>
      <Box
        sx={{
          mb: { md: 3, xs: 4 },
          display: { md: "flex", xs: "flex" },
          ...sx,
          mt: { md: 7, xs: -2 },
        }}
      >
        <Typography variant="subtitle1" sx={{ color: palette.neutral.max }}>
          {title}
        </Typography>
      </Box>
      <Box sx={{ mt: { xs: "-10px", md: "0" } }}>{children}</Box>
    </Container>
  );
};
