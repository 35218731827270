import { Dispatch, SetStateAction } from "react";

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import NightlightRoundedIcon from "@mui/icons-material/NightlightRounded";
import WbTwilightRoundedIcon from "@mui/icons-material/WbTwilightRounded";
import WatchLaterRoundedIcon from "@mui/icons-material/WatchLaterRounded";
import { Control, FieldValues, UseFormRegister } from "react-hook-form";
import { Step } from "../Step";
import moment from "moment";

const ITEMS = [
  {
    name: "morning",
    label: "Morning",
    description: "Before 12:00 PM",
    icon: <LightModeRoundedIcon />,
  },
  {
    name: "afternoon",
    label: "Afternoon",
    description: "Between 12:00 PM to 06:00 PM",
    icon: <WbTwilightRoundedIcon />,
  },
  {
    name: "evening",
    label: "Evening",
    description: "After 06:00 PM",
    icon: <NightlightRoundedIcon />,
  },
  {
    name: "anytime",
    label: "Anytime",
    description: "You and Tasker agree on",
    icon: <WatchLaterRoundedIcon />,
  },
];
interface CalenderPropsType {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues>;
  setTaskData?: any;
  taskData?: any;
}

export const Time = ({
  register,
  control,
  setTaskData,
  taskData,
}: CalenderPropsType): JSX.Element => {
  const { palette } = useTheme();
  const under360 = useMediaQuery("(max-width:360px)");

  const DisableTimePicker = (name: string) => {
    switch (name) {
      case "morning":
        return moment().isAfter(moment("12:00:00", "HH:mm:ss"));
      case "afternoon":
        return moment().isAfter(moment("18:00:00", "HH:mm:ss"));
      case "evening":
        return moment().isAfter(moment().endOf("D"));
      case "anytime":
        return false;
    }
  };
  return (
    <Step title="Date & Time">
      <Box sx={{ width: { xs: "100%", sm: "558px" }, m: "0 auto" }}>
        <Typography variant="h5">Preferred Date & Time</Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            mt: "17px",
          }}
        >
          {ITEMS.map(({ name, label, description, icon }, index) => (
            <Box
              component="label"
              htmlFor={`due_time-${name}`}
              key={index}
              sx={{
                width: { xs: "100%", md: "250px" },
                display: "flex",
                alignItems: "center",
                py: under360 ? "8px" : "14px",
                pl: "16px",
                borderRadius: "8px",
                cursor: "pointer",
                border: {
                  md: `${
                    taskData.due_time === name
                      ? `2px solid ${palette.primary.main}`
                      : taskData.due_date === moment().format("YYYY-MM-DD") &&
                        DisableTimePicker(name)
                      ? `1px solid ${palette.neutral[400]}`
                      : `1px solid ${palette.neutral[100]}`
                  }`,
                  xs: `${
                    taskData.due_time === name
                      ? `2px solid ${palette.primary.main}`
                      : taskData.due_date === moment().format("YYYY-MM-DD") &&
                        DisableTimePicker(name)
                      ? `2px solid ${palette.neutral[400]}`
                      : `2px solid ${palette.neutral[100]}`
                  }`,
                },
                input: { display: "none" },
              }}
            >
              <input
                {...register("due_time", {
                  required: true,
                  onChange: (e) => {
                    const time = e.currentTarget.value;
                    setTaskData((current: any) => ({
                      ...current,
                      due_time: time,
                    }));
                  },
                })}
                type="radio"
                name="due_time"
                value={name}
                id={`due_time-${name}`}
                disabled={
                  taskData.due_date === moment().format("YYYY-MM-DD")
                    ? DisableTimePicker(name)
                    : undefined
                }
              />
              <Box
                sx={{
                  width: "22px",
                  height: "22px",
                  mr: 2,
                  svg: {
                    color:
                      taskData.due_date === moment().format("YYYY-MM-DD") &&
                      DisableTimePicker(name)
                        ? palette.neutral[400]
                        : palette.primary.main,
                  },
                }}
              >
                {icon}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color:
                      taskData.due_date === moment().format("YYYY-MM-DD") &&
                      DisableTimePicker(name)
                        ? palette.neutral[400]
                        : palette.neutral[900],
                  }}
                >
                  {label}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ color: palette.neutral[400] }}
                >
                  {description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Step>
  );
};
