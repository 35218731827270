import API from "./_config"

export function fetchPaymentMethod() {
	return API.get(`payments/method`)
		.then(response => {
			return response.data
		}).catch(e => {
			throw (e)
		})
}

export function addPaymentMethod(pm_token) {
	return API.post(`payments/method`, {
			pm_token
		})
		.then(response => {
			return response.data
		}).catch(e => {
			throw (e)
		})
}

export function deletePaymentMethod() {
	return API.delete(`payments/method`)
		.then(response => {
			return response.data
		}).catch(e => {
			throw (e)
		})
}

export function fetchBankAccount() {
	return API.get(`account/bankaccount`)
		.then(response => {
			return response.data
		}).catch(e => {
			throw (e)
		})
}
export function addBankAccount(data) {
	return API.post(`account/bankaccount`, data)
		.then(response => {
			return response.data
		}).catch(e => {
			throw (e)
		})
}
export function deleteBankAccount(id) {
	return API.delete(`account/bankaccount/${id}`)
		.then(response => {
			return response.data
		}).catch(e => {
			throw (e)
		})
}

export function fetchBillingAddress() {
	return API.get(`account/billingaddress`)
		.then(response => {
			return response.data
		}).catch(e => {
			throw (e)
		})
}

export function addBillingAddress(data) {
	return API.post(`account/billingaddress`, data)
		.then(response => {
			return response.data
		}).catch(e => {
			throw (e)
		})
}

export function deleteBillingAddress() {
	return API.delete(`account/billingaddress`)
		.then(response => {
			return response.data
		}).catch(e => {
			throw (e)
		})
}

export function fetchPaymentHistory(params) {
	return API.get(`/payments/list`, {
			params
		})
		.then(response => {
			return response.data
		}).catch(e => {
			throw (e)
		})
}

export function checkCoupon(data) {
	return API.post(`/coupons/check-coupon`, data)
		.then(response => {
			return response.data
		}).catch(e => {
			throw (e)
		})
}