import { useCallback, useEffect, useState } from "react";

import {
  Box,
  Fade,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Icon } from "components/Icon";

import {
  FieldErrorsImpl,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
} from "react-hook-form";
import Cookies from "js-cookie";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
interface NewJobPropsType {
  setTaskData?: any;
  taskData?: any;

  errors?: FieldErrorsImpl<{
    [x: string]: any;
  }>;
  register: UseFormRegister<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  loading?: boolean;
}
export const NewJob = ({
  register,
  errors,
  clearErrors,
  loading,
  taskData,
  setTaskData,
}: NewJobPropsType): JSX.Element => {
  const [focused, setFocused] = useState<boolean>(false);
  const { palette, breakpoints } = useTheme();
  const isUpMd = useMediaQuery(breakpoints.up("md"));
  const [showAlert, setShowAlert] = useState<boolean>(true);
  const matches = useMediaQuery("(max-width:350px)");

  const closeAlert = () => {
    setShowAlert(false);
  };

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  useEffect(() => {
    if (taskData) {
      setFocused(true);
    }
  }, [taskData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        pb: "48px",
      }}
    >
      <Box sx={{ width: { xs: "100%", sm: "558px" } }}>
        <Box sx={{ mb: "19px" }}>
          <Typography variant="h5">What do you need help with?</Typography>
        </Box>
        <Box
          sx={{
            position: "relative",
            display: { md: "flex", xs: "block" },
            flexDirection: "column",
          }}
        >
          {loading ? (
            <Box
              sx={{
                border: `1px solid ${palette.neutral[100]}`,
                width: "100%",
                height: "55px",
                borderRadius: "4px",
                px: 2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Skeleton
                animation="wave"
                variant="rounded"
                width={45}
                height={12}
                sx={{
                  mr: "8px",
                  position: "absolute",
                  top: -5.5,
                  bgcolor: palette.neutral[100],
                }}
              />
              <Skeleton
                animation="wave"
                variant="rounded"
                width={120}
                height={18}
                sx={{ mr: "8px" }}
              />
            </Box>
          ) : (
            <TextField
              fullWidth
              label="Task title"
              placeholder="e.g. Move out cleaning"
              variant="outlined"
              value={taskData.title || ""}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    color: palette.neutral[400],
                    borderColor: palette.neutral[100],
                    transition: "border 0.3s 0.1s",
                  },
                },
              }}
              onFocus={handleFocus}
              {...register("title", {
                required: true,
                minLength: {
                  value: 10,
                  message: "Must be at least 10 characters",
                },
                maxLength: {
                  value: 100,
                  message: "Maximum 100 characters are allowed",
                },
                onBlur: handleBlur,
                onChange: (e) => {
                  e.persist();
                  clearErrors("title");
                  setTaskData((current: any) => ({
                    ...current,
                    title: e.target.value,
                  }));
                  Cookies.set(
                    "postTask",
                    JSON.stringify({ title: e.target.value })
                  );
                },
              })}
              helperText={
                errors?.title?.message && (
                  <Typography
                    component="span"
                    variant="label"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: palette.error.main,
                    }}
                  >
                    <>
                      <ErrorRoundedIcon sx={{ mr: 0.5, fontSize: "16px" }} />
                      {errors.title.message}
                    </>
                  </Typography>
                )
              }
              FormHelperTextProps={{ sx: { mx: 0 } }}
              error={Boolean(errors?.title?.message)}
            />
          )}
          {isUpMd ? (
            <>
              <Box
                sx={{
                  display: "block",
                  alignItems: "center",
                  position: "absolute",
                  right: { lg: "-300px", md: "-150px" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "48px",
                    height: "48px",
                    borderRadius: "8px",
                    backgroundColor: palette.primary[50],
                    mt: -0.5,
                    mb: { md: "6px" },
                    mr: { md: "", xs: 1 },
                  }}
                >
                  <TipsAndUpdatesOutlinedIcon
                    sx={{
                      color: palette.primary.main,
                      width: "30px",
                      height: "30px",
                    }}
                  />
                </Box>
                <Box sx={{ width: { lg: "227px", md: "120px" } }}>
                  <Typography variant="caption">
                    In a few words tell us what you need done.
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 1,
                  px: 2,
                  width: "100%",
                  borderRadius: "8px",
                  backgroundColor: palette.neutral[50],
                  mt: 2,
                }}
              >
                <TipsAndUpdatesOutlinedIcon
                  sx={{
                    color: palette.primary.main,
                    width: "30px",
                    height: "30px",
                    mr: 1,
                  }}
                />
                <Typography variant="caption">
                  In a few words tell us what you need done.
                </Typography>
              </Box>
            </>
          )}
        </Box>
        {/* <Fade in={showAlert}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              marginTop: "25px",
              background: "linear-gradient(to right, #4353FF, #AB47BC)",
              padding: "15px",
              borderRadius: "8px",
              display: "flex",
              gap: "15px",
            }}
          >
            <Box
              component="img"
              src="/images/Toronto.svg"
              sx={{ display: matches ? "none" : "flex" }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                component="h6"
                variant="h6"
                sx={{
                  color: palette.neutral.min,
                  fontSize: "20px",
                  fontWeight: "700",
                }}
              >
                Taskpin in Toronto
              </Typography>
              <Typography
                component="span"
                variant="label"
                sx={{
                  color: palette.neutral.min,
                  fontSize: "12px",
                }}
              >
                We're excited to bring Taskpin to Toronto, providing a
                convenient solution for outsourcing daily tasks, making life
                easier for the people of this vibrant city!
              </Typography>
            </Box>
            <Box
              onClick={closeAlert}
              sx={{
                mb: "auto",
              }}
            >
              <ClearRoundedIcon
                sx={{
                  cursor: "pointer",
                  fontSize: "24px",
                  color: palette.neutral.min,
                }}
              />
            </Box>
          </Stack>
        </Fade> */}
      </Box>
    </Box>
  );
};
