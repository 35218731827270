import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  Popover,
  Popper,
  Radio,
  RadioGroup,
  Slide,
  Slider,
  Typography,
  styled,
  useAutocomplete,
  useTheme,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { ChangeEvent, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Icon } from "components/Icon";
import { CustomButton } from "components/CustomButton";
import { Item } from "components/Item";
import { getLocations } from "api/postJob/location";

const RangeBoxShadow =
  "0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12);";

const RangeSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 4,
  padding: "0px !important",
  "& .MuiSlider-thumb": {
    height: 28,
    width: 28,
    backgroundColor: "#4353FF",
    " &.Mui-disabled": {
      backgroundColor: theme.palette.neutral[400],
    },
    boxShadow: RangeBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: RangeBoxShadow,
      "@media (hover: none)": {
        boxShadow: RangeBoxShadow,
      },
    },
  },
  "& .MuiSlider-track": {
    border: "none",
    borderRadius: "2px !important",
  },
  "& .MuiSlider-rail": {
    backgroundColor: theme.palette.neutral[200],
    borderRadius: "2px !important",
  },
}));

const InputWrapper = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${theme.palette.neutral[100]};
  color: ${theme.palette.neutral[700]}
  background: #FFFFFF;
  border:none;
  border-radius: 4px 0px 0px 4px;
  flex: none;
  order: 0;
  flex-grow: 1;
  
  & input {
    box-sizing: border-box;
    height:100%;
    padding: 0px 18px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

const linearValues = [0, 1, 2, 3, 4, 5, 6];
const actualValues = [5, 10, 15, 25, 50, 100, 9999];

function mapLinearToActual(value: any) {
  const range = actualValues.length - 1;
  const step = 1 / range;
  const index = Math.round(value / step);
  return actualValues[index];
}
function mapActualToLinear(value: any) {
  const index = actualValues.indexOf(value);
  const range = actualValues.length - 1;
  return index / range;
}

const Listbox = styled("ul")(({ theme }) => ({
  margin: 0,
  padding: 0,
  position: "absolute",
  listStyle: "none",
  backgroundColor: theme.palette.background.paper,
  overflow: "auto",
  maxHeight: "260px",
  background: "#FFFFFF",
}));

export const Neighborhood = ({ setFilter, filter }: any): JSX.Element => {
  const { palette } = useTheme();
  const [modalAnchorEl, setModalAnchorEl] = useState<null | HTMLElement>(null);
  const [type, setType] = useState("");
  const [openSuburb, setOpenSuburb] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [autoCompleteValue, setAutoCompleteValue] = useState<any>([]);
  const [distance, setDistance] = useState<any>(
    mapActualToLinear(filter.distance)
  );

  const TYPE = ["In-Person", "Remote", "Both"];
  const open = Boolean(modalAnchorEl);
  const id = open ? "simple-popper" : undefined;
  const { data } = useQuery(["locations", searchText], () =>
    getLocations(searchText)
  );

  useEffect(() => {
    setOpenSuburb(false);
    setDistance(mapActualToLinear(filter.distance));
  }, [open]);

  useEffect(() => {
    if (filter.task_type === "physical") {
      setType("In-Person");
    } else setType(filter.task_type);
  }, [filter.task_type]);

  // useEffect(() => {
  // }, [open]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setModalAnchorEl(modalAnchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setModalAnchorEl(null);

    setDistance(mapActualToLinear(filter.distance));
    setType(filter.task_type);
  };

  const handleSubmit = () => {
    if (type === "In-Person") {
      setFilter("task_type", "physical");
      setFilter(
        "current_lng",
        String(autoCompleteValue.current_lng) !== "undefined"
          ? String(autoCompleteValue.current_lng)
          : "-79.37687"
      );
      setFilter(
        "current_lat",
        String(autoCompleteValue.current_lat) !== "undefined"
          ? String(autoCompleteValue.current_lat)
          : "43.641186"
      );
      setFilter(
        "location",
        autoCompleteValue.location ? autoCompleteValue.location : "Harbourfront"
      );
      setFilter("distance", mapLinearToActual(distance));
    } else setFilter("task_type", type);

    if (type === "Remote") {
      setFilter("current_lng", "");
      setFilter("current_lat", "");
      setFilter("location", "");
      setFilter("distance", 100);
    }
    if (type !== "Remote") {
      setFilter(
        "current_lng",
        String(autoCompleteValue.current_lng) !== "undefined"
          ? String(autoCompleteValue.current_lng)
          : "-79.37687"
      );
      setFilter(
        "current_lat",
        String(autoCompleteValue.current_lat) !== "undefined"
          ? String(autoCompleteValue.current_lat)
          : "43.641186"
      );
      setFilter(
        "location",
        autoCompleteValue.location ? autoCompleteValue.location : "Harbourfront"
      );
      setFilter("distance", mapLinearToActual(distance));
    }

    setModalAnchorEl(null);
  };

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    setAnchorEl,
  } = useAutocomplete({
    id: "locations-autocomplete",
    options: data?.features
      ? data?.features
      : [
          {
            place_name: "",
          },
        ],
    getOptionLabel: (option: {
      place_name: string;
      text: string;
      center: number[];
    }) => option.place_name || "",
    onChange: (e, value) => {
      if (value) {
        const [lng, lat] = value.center;
        setAutoCompleteValue({
          current_lng: String(lng),
          current_lat: String(lat),
          location: value.text,
        });
        setOpenSuburb(false);
      }
    },
  });

  const handleChange = (event: any, newValue: any) => {
    setDistance(newValue);
  };
  const handleChangeCommitted = (event: any, newValue: any) => {
    setDistance(newValue);
  };

  return (
    <>
      <IconButton
        // disabled={disabled}
        sx={{
          height: {
            md: "35px",
          },
          display: "flex",
          alignItems: "center",
          gap: "4px",
          p: "7.5px 8px 7.5px 8px !important",
          bgcolor: palette.neutral[50],
          color: palette.neutral[900],
          borderRadius: "8px",
          transition: "color 0.3s , background-color 0.3s",
          "&:hover": {
            bgcolor: palette.neutral[100],
            transition: "color 0.3s , background-color 0.3s",
          },
          "&.Mui-focusVisible": {
            outline: `1px solid ${palette.neutral[900]}`,
          },
          ":active": {
            outline: `2px solid ${palette.neutral[900]}`,
          },
          ":disabled": {
            color: palette.neutral[400],
            bgcolor: palette.neutral[100],
          },
        }}
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <Icon
          iconSet="Location"
          sx={{ fontSize: "19px", color: palette.neutral[900] }}
        />
        <Typography variant="body2" sx={{ color: palette.neutral[900] }}>
          {filter.distance && filter.location
            ? `${filter.distance > 100 ? "100km+" : filter.distance + "km"} ${
                !filter.distance && !filter.location && !filter.task_type
                  ? ""
                  : "|"
              } `
            : ""}
          {filter.location
            ? `${filter.location} | `
            : filter.task_type === "Remote"
            ? ""
            : "Harbourfront | "}
          {!filter.distance && !filter.location && !filter.task_type
            ? "Neighborhood"
            : ""}
          {filter.task_type
            ? `${
                filter.task_type === "physical" ? "In-Person" : filter.task_type
              }`
            : ""}
        </Typography>
        <Icon
          iconSet="Arrow2-Down"
          sx={{ fontSize: "19px", mt: 0.4, color: palette.neutral[900] }}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={modalAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-paper": {
            borderRadius: "16px",
            boxShadow: "0px 2px 4px 0px #00000040",
            mt: { md: 2, xs: 4 },
            width: { xs: "100%", md: "auto" },
            display: { md: "unset", xs: "none" },
          },
        }}
      >
        {/* SUBURB MODAL */}
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            bgcolor: palette.neutral.min,
            zIndex: 999,
            display: openSuburb ? "" : "none",
          }}
        >
          <Box sx={{ display: "flex", px: 3, py: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ArrowBackRoundedIcon
                onClick={() => setOpenSuburb(false)}
                sx={{
                  mr: 1,
                  ml: -0.5,
                  color: palette.neutral[500],
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box onClick={(e) => e.preventDefault()} sx={{ width: "100%" }}>
              <Box {...getRootProps()} sx={{ width: "100%" }}>
                <InputWrapper
                  ref={setAnchorEl}
                  sx={{
                    position: "relative",
                    height: "36px",
                    ":focus": {
                      outline: "none",
                      border: "none",
                    },
                    "& ::placeholder": {
                      color: palette.neutral[300],
                    },
                    "& input": {
                      width: "100%",
                    },
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearchText(e.target.value);
                  }}
                >
                  <Icon
                    iconSet="Search"
                    sx={{
                      fontSize: "22px",
                      ml: "12px",
                      color: palette.neutral[500],
                      position: "absolute",
                    }}
                  />
                  <input
                    type="text"
                    value={searchText}
                    className="mobileLocations"
                    placeholder="Search"
                    style={{
                      backgroundColor: palette.neutral[50],
                      borderRadius: "8px",
                      paddingLeft: "36px",
                      height: 38,
                      color: palette.neutral[500],
                    }}
                    {...getInputProps()}
                  />
                </InputWrapper>
              </Box>
            </Box>
          </Box>
          <Box>
            {groupedOptions.length > 0 ? (
              <Listbox
                {...getListboxProps()}
                sx={{
                  position: "relative",
                  width: "100%",
                }}
              >
                {data?.features.map(
                  (
                    option: {
                      place_name: string;
                      text: string;
                      center: number[];
                    },
                    index: number
                  ) => {
                    if (index < 3) {
                      return (
                        <Box
                          key={index}
                          sx={{
                            cursor: "pointer",
                            p: 2,
                          }}
                        >
                          <li
                            key={index}
                            {...getOptionProps({ option, index })}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                color: palette.neutral.max,
                              }}
                            >
                              {option.place_name}
                            </Typography>
                          </li>
                        </Box>
                      );
                    }
                  }
                )}
              </Listbox>
            ) : null}
          </Box>
        </Box>
        {/* END SUBURB MODAL */}
        <Box sx={{ pt: 1, width: { md: "360px", xs: "100%" } }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
              px: 3,
              mb: type === "Remote" ? 2 : 0,
            }}
          >
            {TYPE.map((item) => (
              <CustomButton
                type="empty"
                text={item}
                icon={item === type ? "Check" : ""}
                sx={{
                  color: palette.neutral[900],
                  bgcolor:
                    item === type ? palette.primary[50] : palette.neutral[50],
                }}
                onClick={() => setType(item)}
              />
            ))}
          </Box>
          <Box sx={{ px: 3 }}>
            {type === "Remote" ? null : (
              <>
                <Divider
                  sx={{
                    borderColor: palette.neutral[100],
                    mt: 2,
                  }}
                />
                <Item
                  title="Neighborhood"
                  value={
                    filter.location
                      ? `${filter.location}`
                      : filter.task_type === "Remote"
                      ? ""
                      : "Harbourfront | "
                  }
                  onClick={() => setOpenSuburb(true)}
                />
                <Divider
                  sx={{
                    height: 8,
                    backgroundColor: palette.neutral[50],
                    borderBottom: "unset",
                    borderRadius: "4px",
                  }}
                />
                <Item
                  title="Distance"
                  disable
                  value={
                    mapLinearToActual(distance) === undefined
                      ? `100km+`
                      : mapLinearToActual(distance) === 9999
                      ? `100km+`
                      : `${mapLinearToActual(distance)}km`
                  }
                />
                <RangeSlider
                  disabled={type === "Remote" || !autoCompleteValue}
                  step={0.01}
                  min={0}
                  max={1}
                  marks={linearValues.map((val) => ({
                    value: val,
                    // label: `${mapLinearToActual(val)}`,
                  }))}
                  value={distance}
                  sx={{
                    mt: type === "Remote" ? 1 : 1,
                    mb: "12px",
                    ".css-6cwnna-MuiSlider-mark": {
                      bgcolor: palette.primary.main,
                    },
                    ".css-1tfve6q-MuiSlider-mark": {
                      bgcolor: palette.neutral[100],
                    },
                    ".css-1ejytfe": {
                      bgcolor: palette.neutral[100],
                    },
                  }}
                  onChange={handleChange}
                  onChangeCommitted={handleChangeCommitted}
                />
              </>
            )}
          </Box>
          <Divider
            sx={{
              borderColor: palette.neutral[100],
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
            <CustomButton type="fade" text="Cancel" onClick={handleClose} />
            <CustomButton type="primary" text="Apply" onClick={handleSubmit} />
          </Box>
        </Box>
      </Popover>
      <Modal open={open} sx={{ display: { md: "none", xs: "" } }}>
        <Slide direction="up" in={open} mountOnEnter unmountOnExit>
          <Box
            sx={{
              width: "100%",
              borderBottom: "1px solid var(--light-neutral-n-100, #E9E9E9)",
              justifyContent: "space-between",
              zIndex: 12,
              bgcolor: palette.neutral.min,
              position: "fixed",
              borderRadius: "16px 16px 0 0",
              bottom: 0,
              // animation: `${popupEffect} 1s normal forwards`,
              outline: "none",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                bgcolor: palette.neutral.min,
                zIndex: 999,
                display: openSuburb ? "" : "none",
                borderRadius: "16px 16px 0 0",
              }}
            >
              <Box sx={{ display: "flex", px: 3, py: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ArrowBackRoundedIcon
                    onClick={() => setOpenSuburb(false)}
                    sx={{
                      mr: 1,
                      ml: -0.5,
                      color: palette.neutral[500],
                      cursor: "pointer",
                    }}
                  />
                </Box>
                <Box onClick={(e) => e.preventDefault()} sx={{ width: "100%" }}>
                  <Box {...getRootProps()} sx={{ width: "100%" }}>
                    <InputWrapper
                      ref={setAnchorEl}
                      sx={{
                        position: "relative",
                        height: "36px",
                        ":focus": {
                          outline: "none",
                          border: "none",
                        },
                        "& ::placeholder": {
                          color: palette.neutral[300],
                        },
                        "& input": {
                          width: "100%",
                        },
                      }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setSearchText(e.target.value);
                      }}
                    >
                      <Icon
                        iconSet="Search"
                        sx={{
                          fontSize: "22px",
                          ml: "12px",
                          color: palette.neutral[500],
                          position: "absolute",
                        }}
                      />
                      <input
                        type="text"
                        value={searchText}
                        className="mobileLocations"
                        placeholder="Search"
                        style={{
                          backgroundColor: palette.neutral[50],
                          borderRadius: "8px",
                          paddingLeft: "36px",
                          height: 38,
                          color: palette.neutral[500],
                        }}
                        {...getInputProps()}
                      />
                    </InputWrapper>
                  </Box>
                </Box>
              </Box>
              <Box>
                {groupedOptions.length > 0 ? (
                  <Listbox
                    {...getListboxProps()}
                    sx={{
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    {data?.features.map(
                      (
                        option: {
                          place_name: string;
                          text: string;
                          center: number[];
                        },
                        index: number
                      ) => {
                        if (index < 3) {
                          return (
                            <Box
                              key={index}
                              sx={{
                                cursor: "pointer",
                                p: 2,
                              }}
                            >
                              <li
                                key={index}
                                {...getOptionProps({ option, index })}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: palette.neutral.max,
                                  }}
                                >
                                  {option.place_name}
                                </Typography>
                              </li>
                            </Box>
                          );
                        }
                      }
                    )}
                  </Listbox>
                ) : null}
              </Box>
            </Box>
            {/* END SUBURB MODAL */}
            <Box sx={{ pt: 1, width: { md: "360px", xs: "100%" } }}>
              <Box px={2} mt={1}>
                <Typography variant="h6">Neighborhood</Typography>
                <Divider
                  sx={{
                    borderColor: palette.neutral[100],
                    mt: 1,
                    // mb: "12px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                  px: 2,
                }}
              >
                {TYPE.map((item) => (
                  <CustomButton
                    type="empty"
                    text={item}
                    icon={item === type ? "Check" : ""}
                    sx={{
                      color: palette.neutral[900],
                      bgcolor:
                        item === type
                          ? palette.primary[50]
                          : palette.neutral[50],
                    }}
                    onClick={() => setType(item)}
                  />
                ))}
              </Box>
              <Box sx={{ px: 2 }}>
                {type === "Remote" ? null : (
                  <>
                    <Divider
                      sx={{
                        borderColor: palette.neutral[100],
                        mt: 2,
                      }}
                    />
                    <Item
                      title="Neighborhood"
                      value={
                        filter.location
                          ? `${filter.location}`
                          : filter.task_type === "Remote"
                          ? ""
                          : "Harbourfront | "
                      }
                      onClick={() => setOpenSuburb(true)}
                    />
                    <Divider
                      sx={{
                        height: 8,
                        backgroundColor: palette.neutral[50],
                        borderBottom: "unset",
                        borderRadius: "4px",
                      }}
                    />
                    <Item
                      title="Distance"
                      disable
                      value={
                        mapLinearToActual(distance) === undefined
                          ? `100km+`
                          : mapLinearToActual(distance) === 9999
                          ? `100km+`
                          : `${mapLinearToActual(distance)}km`
                      }
                    />
                    <RangeSlider
                      disabled={type === "Remote" || !autoCompleteValue}
                      step={0.01}
                      min={0}
                      max={1}
                      marks={linearValues.map((val) => ({
                        value: val,
                        // label: `${mapLinearToActual(val)}`,
                      }))}
                      value={distance}
                      sx={{
                        mt: type === "Remote" ? 1 : 1,
                        mb: "12px",
                        ".css-6cwnna-MuiSlider-mark": {
                          bgcolor: palette.primary.main,
                        },
                        ".css-1tfve6q-MuiSlider-mark": {
                          bgcolor: palette.neutral[100],
                        },
                        ".css-1ejytfe": {
                          bgcolor: palette.neutral[100],
                        },
                      }}
                      onChange={handleChange}
                      onChangeCommitted={handleChangeCommitted}
                    />
                  </>
                )}
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", p: 2 }}
              >
                <CustomButton type="fade" text="Cancel" onClick={handleClose} />
                <CustomButton
                  type="primary"
                  text="Apply"
                  onClick={handleSubmit}
                />
              </Box>
            </Box>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};
