import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./app";
import MToastr from "react-redux-toasts";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./sass/app.scss";
import {
  LIVECHAT_LICENCE_ID,
  SOCKET_URL,
  STRIPE_KEY,
  STRIPE_KEY_PRODUCTION,
} from "constants/config";
// import Cookies from "js-cookie";
import Cookies from "js-cookie";
import { isDevelopment, redircetToLogin } from "utils";
import LiveChat from "react-livechat";
import { io } from "socket.io-client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "@mui/material";
import { lightTheme } from "constants/theme";

const stripePromise = loadStripe(
  isDevelopment ? STRIPE_KEY : STRIPE_KEY_PRODUCTION
);

if (process.env.NODE_ENV === "production") {
  if (Cookies.get("token")) localStorage.setItem("token", Cookies.get("token"));
  else redircetToLogin();
}

export const socket = io.connect(SOCKET_URL, { transports: ["websocket"] });
export const SocketContext = React.createContext();

const NotifAlertContext = React.createContext();

export default NotifAlertContext;

function Panel() {
  const queryClient = new QueryClient();

  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <Provider store={store}>
          <Elements stripe={stripePromise}>
            <QueryClientProvider client={queryClient}>
              <SocketContext.Provider value={socket}>
                <App />
              </SocketContext.Provider>
            </QueryClientProvider>
          </Elements>
          <MToastr />
        </Provider>
      </ThemeProvider>
      {process.env.NODE_ENV === "production" &&
        process.env.REACT_APP_BASE_URL === "https://app.jobtick.com/" && (
          <LiveChat license={LIVECHAT_LICENCE_ID} />
        )}
    </>
  );
}

ReactDOM.render(<Panel />, document.getElementById("root"));
