import { Box, Typography, useTheme } from "@mui/material";
import { fetchAccount } from "api/exploreJob";
import { Icon } from "components/Icon";
import { PUBLIC_WEBSITE_URL } from "constants/config";
import { ReactNode, useContext, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import LayoutContext from "../Context";
import NotificationPopup from "components/NotificationPopup";

interface MainNavType {
  mode: string;
  isOther: boolean;
}
interface NavItemType {
  title: string;
  icon: ReactNode;
  activeIcon?: ReactNode;
  url: string;
}

export const MainNav = ({ mode, isOther }: MainNavType): JSX.Element => {
  const { palette } = useTheme();
  const { MessageBadgeCount } = useContext(LayoutContext);
  const history = useHistory();
  const location = useLocation();

  const tickerItems: Array<NavItemType> = [
    {
      title: "Explore",
      icon: (
        <Icon
          iconSet="Search"
          sx={{ fontSize: "24px", color: palette.neutral[500] }}
        />
      ),
      activeIcon: (
        <Icon
          iconSet="Search"
          sx={{ fontSize: "24px", color: palette.neutral[900] }}
        />
      ),
      url: "/explore-tasks",
    },
    {
      title: "Workspace",
      icon: (
        <Icon
          iconSet="Work"
          sx={{ fontSize: "24px", color: palette.neutral[500] }}
        />
      ),
      activeIcon: (
        <Icon
          iconSet="Work-Filled"
          sx={{ fontSize: "24px", color: palette.neutral[900] }}
        />
      ),
      url: "/workspace",
    },

    {
      title: "Messaging",
      icon: (
        <Icon
          iconSet="Chat"
          sx={{ fontSize: "24px", color: palette.neutral[500] }}
        />
      ),
      activeIcon: (
        <Icon
          iconSet="Chat-Filled"
          sx={{ fontSize: "24px", color: palette.neutral[900] }}
        />
      ),
      url: "/messaging",
    },
  ];
  const posterItems: Array<NavItemType> = [
    {
      title: "Inventory",
      icon: (
        <Icon
          iconSet="Inventory"
          sx={{ fontSize: "24px", color: palette.neutral[500] }}
        />
      ),
      activeIcon: (
        <Icon
          iconSet="Inventory-Filled"
          sx={{ fontSize: "24px", color: palette.neutral[900] }}
        />
      ),
      url: "/inventory",
    },
    {
      title: "Messaging",
      icon: (
        <Icon
          iconSet="Chat"
          sx={{ fontSize: "24px", color: palette.neutral[500] }}
        />
      ),
      activeIcon: (
        <Icon
          iconSet="Chat-Filled"
          sx={{ fontSize: "24px", color: palette.neutral[900] }}
        />
      ),
      url: "/messaging",
    },
    // {
    //   title: "How It Works",
    //   icon: <Icon iconSet="Help" sx={{ fontSize: "18px" }} />,
    //   url: `${PUBLIC_WEBSITE_URL}helpdesk/Taskpin/how-taskpin-works`,
    // },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",

        alignSelf: "center",
        justifySelf: "center",
      }}
    >
      <Box sx={{ display: "flex", gap: 2 }}>
        {mode === "ticker" &&
          tickerItems.map(({ title, icon, activeIcon, url }, index) => (
            <Link key={index} to={url}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  px: "6.5px",
                  mt: "0.1px",
                  position: "relative",
                }}
              >
                {title === "Payments" &&
                history.location.pathname?.startsWith("/profile-poster") &&
                location.search === "?payment-history"
                  ? activeIcon
                  : history.location.pathname?.startsWith(url) &&
                    !isOther &&
                    activeIcon
                  ? activeIcon
                  : icon}
                <Typography
                  variant="label"
                  sx={{
                    color:
                      title === "Payments" &&
                      history.location.pathname?.startsWith(
                        "/profile-poster"
                      ) &&
                      location.search === "?payment-history"
                        ? palette.neutral[900]
                        : history.location.pathname?.startsWith(url) && !isOther
                        ? palette.neutral[900]
                        : palette.neutral[500],
                  }}
                >
                  {title}
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    bgcolor: palette.neutral[900],
                    height: "2px",
                    display:
                      history.location.pathname?.startsWith(url) && !isOther
                        ? `flex`
                        : "none",
                    position: "absolute",
                    bottom: -4,
                  }}
                />
              </Box>
            </Link>
          ))}
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        {mode === "poster" &&
          posterItems.map(({ title, icon, activeIcon, url }, index) =>
            title === "How It Works" ? (
              <a
                key={index}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color:
                      history.location.pathname?.startsWith(url) && !isOther
                        ? palette.primary.main
                        : palette.neutral[700],
                  }}
                >
                  {history.location.pathname?.startsWith(url) &&
                  !isOther &&
                  activeIcon
                    ? activeIcon
                    : icon}
                  <Typography
                    component="span"
                    variant="button"
                    sx={{ ml: 1, mr: 3 }}
                  >
                    {title}
                  </Typography>
                </Box>
              </a>
            ) : (
              <Link key={index} to={url}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    px: "6.5px",
                    mt: "0.1px",
                    position: "relative",
                  }}
                >
                  {title === "Payments" &&
                  history.location.pathname?.startsWith("/profile-poster") &&
                  location.search === "?payment-history"
                    ? activeIcon
                    : history.location.pathname?.startsWith(url) &&
                      !isOther &&
                      activeIcon
                    ? activeIcon
                    : icon}
                  <Typography
                    variant="label"
                    sx={{
                      color:
                        title === "Payments" &&
                        history.location.pathname?.startsWith(
                          "/profile-poster"
                        ) &&
                        location.search === "?payment-history"
                          ? palette.neutral[900]
                          : history.location.pathname?.startsWith(url) &&
                            !isOther
                          ? palette.neutral[900]
                          : palette.neutral[500],
                    }}
                  >
                    {title}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      bgcolor: palette.neutral[900],
                      height: "2px",
                      display:
                        history.location.pathname?.startsWith(url) && !isOther
                          ? `flex`
                          : "none",
                      position: "absolute",
                      bottom: -4,
                    }}
                  />
                </Box>
              </Link>
            )
          )}

        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            px: "6.5px",
          }}
        >
          <Icon iconSet="DefaultNotif" sx={{ color: palette.neutral[500] }} />
          <Typography
            variant="label"
            sx={{
              color: palette.neutral[500],
            }}
          >
            Activity
          </Typography>
        </Box> */}
      </Box>
      <NotificationPopup />
    </Box>
  );
};
