import {
  Box,
  Button,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Modal,
  Slide,
  TextField,
  Typography,
  keyframes,
  useTheme,
} from "@mui/material";
import { fetchSkills, updateSkills } from "api/profile/index";
import { CustomButton } from "components/CustomButton";
import { Icon } from "components/Icon";
import { useEffect, useState } from "react";
import useAccountInfoStore from "store/zustand/accountInformation";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import toast from "react-hot-toast";
import AlertNotif from "components/AlertNotif";

const Skills = ({ setPages }: { setPages: any }): JSX.Element => {
  const { palette } = useTheme();
  const myEffect = keyframes`
  0% {
    transform: rotate(0deg);

  }
  100% {
    transform: rotate(360deg);
  }
`;
  const AccountInfo: any = useAccountInfoStore(
    (state) => state.AccountInformation
  );
  const setAccountInfo = useAccountInfoStore(
    (state) => state.setAccountInformation
  );
  const [filterSkill, setFilterSkill] = useState<string>("");
  const [calculatedCategories, setCalculatedCategories] = useState<any>([]);
  const [Categories, setCategories] = useState<any>([]);
  const [allSkills, setAllSkills] = useState<any>([]);
  const [skillTitle, setSkillTitle] = useState<any>([]);
  const [newSkill, setNewSkill] = useState<any>([]);
  const [disabling, setDisabling] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  let acronym = filterSkill.substring(0, 1);
  let uniqueChars = Categories.filter((c: any, index: any) => {
    return Categories.indexOf(c) === index;
  });

  useEffect(() => {
    setNewSkill({ skills: Categories });
  }, [Categories]);
  useEffect(() => {
    allSkills.map((item: any) => {
      setSkillTitle((previousCategoris: any) => {
        return [...previousCategoris, item?.title];
      });
    });
  }, [allSkills]);
  useEffect(() => {
    AccountInfo?.skills?.skills.map((item: any) => {
      if (Categories.indexOf(item) > -1 === false) {
        setCategories((prev: any) => [
          ...prev,
          item.name?.charAt(0).toUpperCase() + item.name.slice(1),
        ]);
      }
    });
  }, [AccountInfo?.skills?.skills?.length]);

  useEffect(() => {
    if (allSkills.length === 0) {
      fetchSkills().then((res) => {
        setLoading(false);
        setAllSkills(
          [...res.data].sort((a, b) => (a.title > b.title ? 1 : -1))
        );
      });
    }
    if (allSkills.length > 0) {
      const data = allSkills.reduce((accumulator: any, currentValue: any) => {
        const title = currentValue.title[0];
        if (!accumulator[title])
          accumulator[title] = { title, content: [currentValue] };
        else accumulator[title].content.push(currentValue);
        return { ...accumulator };
      }, {});
      const CATEGORIES: any = Object.values(data);
      setCalculatedCategories(CATEGORIES);
    }
  }, [allSkills]);

  const handleSelectCategory = (e: any, item: string, itemId: any) => {
    if (Categories && Categories.indexOf(item) > -1 === false) {
      setCategories((previousCategoris: any) => {
        return [...previousCategoris, item];
      });
    } else {
      setCategories((previousCategoris: any) => {
        const filteredCategories = previousCategoris.filter(
          (category: any) => category !== item
        );
        return filteredCategories;
      });
    }
  };

  const submitAddSkill = () => {
    toast.promise(
      updateSkills(newSkill)
        .then((res) => {
          setDisabling(false);
          toast.custom((t) => (
            <AlertNotif title={res.message} type="success" target={t} />
          ));
          if (!AccountInfo?.position) {
            setPages("Location");
          } else {
            setShow(false);
          }
          setAccountInfo(res.data);
        })
        .catch((err) => {
          setDisabling(false);
        }),
      {
        loading: "Saving...",
        success: (res) => ``,
        error: (err) => ``,
      },
      {
        style: {
          backgroundColor: palette.primary[50],
          borderBottom: `2px solid ${palette.primary.main}`,
        },
        loading: {
          icon: (
            <Icon
              iconSet="Cached"
              sx={{
                color: palette.primary.main,
                animation: `${myEffect} 1s normal infinite`,
              }}
            />
          ),
        },
        success: {
          style: { display: "none" },
        },
        error: {
          style: { display: "none" },
        },
      }
    );
  };

  return (
    <Modal
      open={show}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Slide direction="up" in={show} mountOnEnter unmountOnExit>
        <Box
          sx={{
            // position: "absolute" as "absolute",
            // top: "50%",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: { md: "16px", xs: "0" },
            py: 4,
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            alignItems: { md: "center", xs: "start" },
            outline: "none !important",
            width: {
              md: "917px",
              xs: "100%",
            },
            height: { md: "623px", xs: "100%" },
          }}
        >
          <Box
            sx={{
              width: "327px",
              textAlign: { md: "center", xs: "start" },
              px: { xs: 2, md: 0 },
            }}
          >
            <Typography variant="h2">Choose Your Skills</Typography>
            <Typography
              variant="body1"
              color={palette.neutral[700]}
              sx={{ mt: 1 }}
            >
              Choose the skills that best describe you
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 2,
              width: "100%",
              px: { md: 4, xs: 2 },
              height: "100%",
            }}
          >
            <TextField
              fullWidth
              placeholder="Enter your skill"
              value={filterSkill}
              onChange={(e) =>
                setFilterSkill(
                  e.target.value?.charAt(0).toUpperCase() +
                    e.target.value.slice(1)
                )
              }
              sx={{
                "& .MuiInputBase-root": {
                  height: 38,
                  borderRadius: "8px",
                  backgroundColor: palette.neutral[50],
                  color: palette.neutral[500],
                },
                pb: 2,
                bgcolor: palette.neutral.min,

                fieldset: {
                  border: "unset",
                },
                input: {
                  p: 0,
                },
                MuiInput: {
                  input: {
                    "&::placeholder": {
                      color: palette.neutral[500],
                    },
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon iconSet="Search" />
                  </InputAdornment>
                ),
                endAdornment: filterSkill ? (
                  <InputAdornment position="end">
                    <Icon
                      iconSet="Close"
                      sx={{
                        color: palette.neutral[500],
                        mr: -0.5,
                        cursor: "pointer",
                        borderRadius: "100%",
                      }}
                      handleClick={() => {
                        setFilterSkill("");
                      }}
                    />
                  </InputAdornment>
                ) : null,
              }}
            />

            <Box
              sx={{
                overflow: "overlay",
                height: { md: "346px", xs: "100%" },
                width: "100%",
                pb: { xs: "220px", md: 0 },
              }}
            >
              {Categories?.length > 0 && !filterSkill ? (
                <>
                  <Box sx={{}}>
                    <Typography component="span" variant="body2">
                      Your Skills
                    </Typography>
                    <Box
                      sx={{
                        mt: 2,
                        flexWrap: "wrap",
                        display: "flex",
                        height: "auto",
                        gap: 1,
                        mb: 2,
                      }}
                    >
                      {uniqueChars?.map((item: any, index: number) => {
                        if (item) {
                          return (
                            <Box
                              key={index}
                              sx={{
                                bgcolor: palette.primary[50],
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                py: "6px !important",
                                pl: 1,
                                pr: 2,
                                borderRadius: "8px",
                                color: palette.neutral[900],
                                "&:hover": {
                                  cursor: "pointer",
                                },
                                height: { xs: "48px", md: "" },
                              }}
                              onClick={(e: any) => {
                                let filteredArray = Categories.filter(
                                  (cats: any) => cats !== item
                                );
                                setCategories(filteredArray);
                              }}
                            >
                              <CheckRoundedIcon
                                sx={{
                                  color: palette.neutral.max,
                                  fontSize: "20px",
                                  mr: "4px",
                                }}
                              />
                              <Typography component="span" variant="body2">
                                {item}
                              </Typography>
                            </Box>
                          );
                        }
                      })}
                    </Box>
                  </Box>
                </>
              ) : null}
              {filterSkill ? (
                <Box
                  sx={{
                    py: 2,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    disabled={
                      skillTitle.indexOf(filterSkill) > -1 ? true : false
                    }
                    type="submit"
                    sx={{
                      color: palette.primary.main,
                      "&:hover": { backgroundColor: "unset" },
                      p: "0px !important",
                      height: { md: 35, xs: 40 },
                    }}
                    onClick={() => {
                      setCategories((prev: any) => [
                        ...prev,
                        filterSkill?.charAt(0).toUpperCase() +
                          filterSkill.slice(1),
                      ]);
                      setFilterSkill("");
                    }}
                  >
                    <Typography component="span" variant="subtitle2">
                      Save “{filterSkill}” as new skill
                    </Typography>
                  </Button>
                </Box>
              ) : null}
              {loading ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    className="loader"
                    sx={{ width: { xs: "90px", md: "60px" } }}
                  />
                </Box>
              ) : (
                calculatedCategories.map((category: any, index: any) => {
                  if (
                    skillTitle
                      .map((item: any) => {
                        return item.indexOf(filterSkill) > -1;
                      })
                      .includes(true)
                  )
                    if (
                      category.title
                        .toLowerCase()
                        .includes(acronym.toLowerCase())
                    )
                      return (
                        <Box key={index}>
                          <ListSubheader
                            sx={{
                              color: palette.neutral[900],
                              px: 0,
                              py: 0,
                              pb: 1,
                            }}
                          >
                            <Typography variant={"body2"}>
                              {category.title}
                            </Typography>
                          </ListSubheader>
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              mt: 1,
                              flexWrap: "wrap",
                              mb: 2,
                              height: "auto",
                            }}
                          >
                            {calculatedCategories[index].content?.map(
                              (item: any, categoryIndex: any) => {
                                if (
                                  item.title
                                    .toLowerCase()
                                    .includes(filterSkill.toLowerCase())
                                )
                                  if (Categories.indexOf(item.title) < 0) {
                                    return (
                                      <Box key={categoryIndex}>
                                        <MenuItem
                                          disableRipple
                                          value={categoryIndex}
                                          onClick={(e) =>
                                            handleSelectCategory(
                                              e,
                                              item.title,
                                              item.id
                                            )
                                          }
                                          sx={{
                                            bgcolor: palette.neutral[50],
                                            display: "flex",
                                            justifyContent: "space-between",
                                            px: "16px !important",
                                            py: "6px !important",
                                            borderRadius: "8px",
                                            "&.Mui-selected": {
                                              bgcolor: palette.neutral[50],
                                            },
                                            "&.Mui-selected:hover": {
                                              bgcolor: palette.neutral[50],
                                            },

                                            transition:
                                              "color 0.3s,background-color 0.3s",
                                            color: palette.neutral[900],
                                            "&:hover": {
                                              cursor: "pointer",
                                              bgcolor: palette.neutral[900],
                                              color: palette.neutral.min,
                                              transition:
                                                "color 0.3s,background-color 0.3s",
                                              span: {
                                                color: palette.neutral.min,
                                              },
                                            },
                                          }}
                                        >
                                          <Typography
                                            variant={"body2"}
                                            sx={{
                                              color: palette.neutral.max,
                                            }}
                                          >
                                            {item.title}
                                          </Typography>
                                          {Categories &&
                                            Categories?.includes(
                                              item.title
                                            ) && (
                                              <CheckRoundedIcon
                                                sx={{
                                                  color: palette.primary.main,
                                                  fontSize: "20px",
                                                  ml: "4px",
                                                }}
                                              />
                                            )}
                                        </MenuItem>
                                      </Box>
                                    );
                                  }
                              }
                            )}
                          </Box>
                        </Box>
                      );
                })
              )}
            </Box>
          </Box>
          <Box
            sx={{
              width: { md: "208px", xs: "100%" },
              bottom: { md: 32, xs: 0 },
              position: "absolute",
              px: "16px",
              bgcolor: palette.neutral.min,
              zIndex: 100,
              py: { xs: 2, md: 0 },
            }}
          >
            <CustomButton
              type="primary"
              text="Save & Continue"
              sx={{ width: "100%" }}
              onClick={submitAddSkill}
              disabled={newSkill?.skills?.length < 1 || disabling}
            />
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default Skills;
