import { createStore, combineReducers } from "redux";

import { accountReducer } from "./reducers/account-reducer.js";
import { toastReducer } from "react-redux-toasts";
import { notificationReducer } from "./reducers/notificationReducer.js";
import { walletReducer } from "./reducers/walletReducer.js";
import { socketReducer } from "./reducers/socketReducer.js";
import { categoryReducer } from "./reducers/appReducer.js";
import { isDevelopment } from "utils";
import { modeReducer } from "./reducers/modeReducer.js";
import Cookies from "js-cookie";

const loadState = () => {
  try {
    const landingMode = Cookies.get("landingMode");
    const mode = Cookies.get("mode");
    if (landingMode){
      Cookies.remove("landingMode", {
        domain: window.location.origin.includes("localhost")
          ? undefined
          : ".taskpin.ca",
      })
      return { mode: { mode: landingMode} };
    }
    else if (mode === null && landingMode=== null ) {
      return { mode: { mode: "poster" } };
    }
    return JSON.parse(mode);
  } catch (err) {
    return { mode: { mode: "poster" } };
  }
};

const saveState = (state) => {
  try {
    const serialState = JSON.stringify(state);
    Cookies.set("mode", serialState);
  } catch (err) {
    console.log(err);
  }
};

const mode = loadState();

const rootReducer = combineReducers({
  app: combineReducers({
    category: categoryReducer,
  }),
  account: accountReducer,
  mode: modeReducer,
  notification: notificationReducer,
  socketStatus: socketReducer,
  wallet: walletReducer,
  toastr: toastReducer,
});

const store = isDevelopment
  ? createStore(
      rootReducer,
      mode,
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  : createStore(rootReducer, mode);

store.subscribe(() => {
  saveState({
    mode: store.getState().mode,
  });
});

export default store;
