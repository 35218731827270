import API from "./_config";

export function getNotificationCountBadge() {
  return API.get(`notifications/badge-status`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function clearNotificationCountBadge(user_id) {
  return API.get(`notifications/badge-clear?trigger=conversation&id=${user_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function clearOfferCountBadge(user_id) {
  return API.get(`notifications/badge-clear?trigger=offer&id=${user_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
