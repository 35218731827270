import { useEffect, useState } from "react";

import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileDatePicker } from "@mui/x-date-pickers";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import NightlightRoundedIcon from "@mui/icons-material/NightlightRounded";
import WbTwilightRoundedIcon from "@mui/icons-material/WbTwilightRounded";
import WatchLaterRoundedIcon from "@mui/icons-material/WatchLaterRounded";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import moment from "moment";

import { Step } from "../Step";
import { Icon } from "components/Icon";

const ITEMS = [
  {
    name: "morning",
    label: "Morning",
    description: "Before 12:00 PM",
    icon: <LightModeRoundedIcon />,
  },
  {
    name: "afternoon",
    label: "Afternoon",
    description: "Between 12:00 PM to 06:00 PM",
    icon: <WbTwilightRoundedIcon />,
  },
  {
    name: "evening",
    label: "Evening",
    description: "After 06:00 PM",
    icon: <NightlightRoundedIcon />,
  },
  {
    name: "anytime",
    label: "Anytime",
    description: "You and Tasker agree on",
    icon: <WatchLaterRoundedIcon />,
  },
];
interface CalenderPropsType {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues>;
  setTaskData?: any;
  taskData?: any;
  copyJob?: string;
}

export const Calendar = ({
  setTaskData,
  taskData,
  register,
  control,
  copyJob,
}: CalenderPropsType): JSX.Element => {
  const { palette, breakpoints } = useTheme();
  const smallSize = useMediaQuery(breakpoints.down("md"));
  const [invalidDate, setInvalidDate] = useState(false);
  const today = new Date();
  useEffect(() => {
    if (copyJob) {
      setTaskData((current: any) => ({
        ...current,
        due_date: moment().format("YYYY-MM-DD"),
      }));
    } else
      setTaskData((current: any) => ({
        ...current,
        due_date: taskData.due_date
          ? taskData.due_date
          : moment().format("YYYY-MM-DD"),
      }));
  }, []);

  const DisableTimePicker = (name: string) => {
    switch (name) {
      case "morning":
        return moment().isAfter(moment("12:00:00", "HH:mm:ss"));
      case "afternoon":
        return moment().isAfter(moment("18:00:00", "HH:mm:ss"));
      case "evening":
        return moment().isAfter(moment().endOf("D"));
      case "anytime":
        return false;
    }
  };

  return (
    <Step title="Date & Time">
      <Box
        sx={{
          width: { xs: "100%", sm: "558px" },
          m: "0 auto",
        }}
      >
        <Typography variant="h5">Preferred Date & Time</Typography>
        <Controller
          control={control}
          name="due_date"
          render={({ field: { ref, onChange, ...field } }) => (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                disablePast
                label="Date"
                disableHighlightToday
                inputFormat="YYYY-MM-DD"
                value={taskData.due_date}
                onError={(err) => {
                  if (err) {
                    setInvalidDate(true);
                  } else {
                    setInvalidDate(false);
                  }
                }}
                onChange={(date) => {
                  setTaskData((current: any) => ({
                    ...current,
                    due_date: moment(date).format("YYYY-MM-DD"),
                  }));
                }}
                minDate={moment()}
                maxDate={moment().add(3, "months")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...field}
                    inputRef={ref}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          color: palette.neutral[400],
                          borderColor: palette.neutral[100],
                          transition: "border 0.3s 0.1s",
                        },
                      },
                      mt: "17px",
                      mb: "34px",
                    }}
                    fullWidth
                    helperText={invalidDate ? "Invalid input date" : ""}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon iconSet="Event-Filled" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
        <Box
          sx={{
            display: smallSize ? "none" : "flex",
            flexWrap: "wrap",
            gap: 1,
            justifyContent: "space-between",
          }}
        >
          {ITEMS.map(({ name, label, description, icon }, index) => (
            <Box
              component="label"
              htmlFor={`due_time-${name}`}
              key={index}
              sx={{
                width: { xs: "328px", md: "271.5px" },
                display: "flex",
                alignItems: "center",
                py: 0.5,
                pl: 2,
                borderRadius: "8px",
                cursor: "pointer",
                border: {
                  md: `${
                    taskData.due_time === name
                      ? `1px solid ${palette.primary.main}`
                      : taskData.due_date === moment().format("YYYY-MM-DD") &&
                        DisableTimePicker(name)
                      ? `1px solid ${palette.neutral[400]}`
                      : `1px solid ${palette.neutral[100]}`
                  }`,
                  xs: `${
                    taskData.due_time === name
                      ? `2px solid ${palette.primary.main}`
                      : taskData.due_date === moment().format("YYYY-MM-DD") &&
                        DisableTimePicker(name)
                      ? `2px solid ${palette.neutral[400]}`
                      : `2px solid ${palette.neutral[100]}`
                  }`,
                },
                outline:
                  taskData.due_time === name ? "3px solid transparent" : "",

                outlineWidth: "2px",
                outlineColor: { xs: "none", lg: palette.primary.main },
                input: { display: "none" },
              }}
            >
              <input
                {...register("due_time", {
                  required: true,
                  onChange: (e) => {
                    e.persist();

                    setTaskData((current: any) => ({
                      ...current,
                      due_time: e?.target?.value,
                    }));
                  },
                })}
                type="radio"
                name="due_time"
                value={name}
                id={`due_time-${name}`}
                disabled={
                  taskData.due_date === moment().format("YYYY-MM-DD")
                    ? DisableTimePicker(name)
                    : undefined
                }
              />
              <Box
                sx={{
                  width: "22px",
                  height: "22px",
                  mr: 1,
                  svg: {
                    color:
                      taskData.due_date === moment().format("YYYY-MM-DD") &&
                      DisableTimePicker(name)
                        ? palette.neutral[400]
                        : palette.primary.main,
                  },
                }}
              >
                {icon}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color:
                      taskData.due_date === moment().format("YYYY-MM-DD") &&
                      DisableTimePicker(name)
                        ? palette.neutral[400]
                        : palette.neutral[900],
                  }}
                >
                  {label}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ color: palette.neutral[400] }}
                >
                  {description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Step>
  );
};
