import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE + "api/v1",
});
API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token === null) {
      return config;
    }
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.Version = process.env.REACT_APP_Version;
    return config;
  },
  (err) => Promise.reject(err)
);
API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const APIV2 = axios.create({
  baseURL: process.env.REACT_APP_API_BASE + "api/v2",
});
APIV2.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token === null) {
      return config;
    }
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.Version = process.env.REACT_APP_Version;
    return config;
  },
  (err) => Promise.reject(err)
);
APIV2.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const APIV3 = axios.create({
  baseURL: process.env.REACT_APP_API_BASE + "api/v3",
});
APIV3.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token === null) {
      return config;
    }
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.Version = process.env.REACT_APP_Version;
    return config;
  },
  (err) => Promise.reject(err)
);
APIV3.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default API;
