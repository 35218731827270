import { IconButton, Typography, keyframes, useTheme } from "@mui/material";
import { Icon } from "../Icon";

interface ButtonType {
  type: string;
  text: string;
  sx?: any;
  disabled?: boolean;
  icon?: any;
  onClick?: any;
  error?: boolean;
}

export const CustomButton = ({
  type,
  text,
  sx,
  disabled,
  icon,
  onClick,
  error,
}: ButtonType) => {
  const { palette } = useTheme();
  const myEffect = keyframes`
  0% {
    transform: rotate(0deg);

  }
  100% {
    transform: rotate(360deg);
  }
`;
  const statusSwitch = (type: string) => {
    switch (type) {
      case "primary":
        return (
          <IconButton
            type="submit"
            disabled={disabled}
            onClick={onClick}
            sx={{
              height: {
                md: "35px",
                xs: "40px",
              },
              display: "flex",
              alignItems: "center",
              py: 1,
              px: 2,
              bgcolor: palette.primary.main,
              color: palette.neutral.min,
              borderRadius: "8px",
              transition: "background-color 0.3s",
              "&:hover": {
                bgcolor: palette.neutral[900],
                transition: "background-color 0.3s",
              },
              "&.Mui-focusVisible": {
                backgroundColor: palette.primary.main,
                outline: `2px solid #FF7F00`,
              },
              ":active": {
                backgroundColor: palette.primary[700],
              },
              ":disabled": {
                backgroundColor: palette.neutral[100],
                color: palette.neutral[400],
              },
              ...sx,
            }}
          >
            {icon && <Icon iconSet={icon} sx={{ mr: 0.5, fontSize: "19px" }} />}
            <Typography variant="button">{text}</Typography>
          </IconButton>
        );
      case "rounded":
        return (
          <IconButton
            disabled={disabled}
            onClick={onClick}
            sx={{
              height: {
                md: "35px",
                xs: "40px",
              },
              display: "flex",
              alignItems: "center",
              py: 1,
              px: 2,
              bgcolor: palette.neutral.min,
              color: palette.primary.main,
              border: `2px solid ${palette.primary.main}`,
              borderRadius: "8px",
              transition: "background-color 0.3s",
              "&:hover": {
                bgcolor: palette.primary[50],
                transition: "background-color 0.3s",
              },
              "&.Mui-focusVisible": {
                bgcolor: palette.primary[50],
                outline: `2px solid #FF7F00`,
              },
              ":active": {
                backgroundColor: palette.neutral[900],
                border: `2px solid ${palette.neutral[900]}`,
                color: palette.neutral.min,
              },
              ":disabled": {
                backgroundColor: palette.neutral.min,
                border: `2px solid ${palette.neutral[400]}`,
                color: palette.neutral[400],
              },
              ...sx,
            }}
          >
            {icon && <Icon iconSet={icon} sx={{ mr: 0.5, fontSize: "19px" }} />}
            <Typography variant="button">{text}</Typography>
          </IconButton>
        );
      case "empty":
        return (
          <IconButton
            disabled={disabled}
            onClick={onClick}
            sx={{
              height: {
                md: "35px",
                xs: "40px",
              },
              display: "flex",
              alignItems: "center",
              py: 1,
              px: 2,
              bgcolor: palette.neutral.min,
              color: error ? palette.error.main : palette.primary.main,
              borderRadius: "8px",
              transition: "background-color 0.3s",
              "&:hover": {
                bgcolor: error ? palette.error.light : palette.primary[50],
                transition: "background-color 0.3s",
              },
              "&.Mui-focusVisible": {
                outline: `2px solid #FF7F00`,
              },
              ":active": {
                bgcolor: error ? palette.error.light : palette.primary[50],
              },
              ":disabled": {
                color: palette.neutral[400],
              },
              ...sx,
            }}
          >
            {icon && <Icon iconSet={icon} sx={{ mr: 0.5, fontSize: "19px" }} />}
            <Typography variant="button">{text}</Typography>
          </IconButton>
        );
      case "fade":
        return (
          <IconButton
            disabled={disabled}
            onClick={onClick}
            sx={{
              height: {
                md: "35px",
                xs: "40px",
              },
              display: "flex",
              alignItems: "center",
              py: 1,
              px: 2,
              bgcolor: error ? palette.error.light : palette.primary[50],
              color: error ? palette.error.main : palette.primary.main,
              borderRadius: "8px",
              transition: "color 0.3s , background-color 0.3s",
              "&:hover": {
                bgcolor: palette.neutral[900],
                color: palette.neutral.min,
                transition: "color 0.3s , background-color 0.3s",
              },
              "&.Mui-focusVisible": {
                outline: `2px solid #FF7F00`,
              },
              ":active": {
                bgcolor: palette.primary.main,
                color: palette.neutral.min,
              },
              ":disabled": {
                color: palette.neutral[400],
                bgcolor: palette.neutral[100],
              },
              ...sx,
            }}
          >
            {icon && <Icon iconSet={icon} sx={{ mr: 0.5, fontSize: "19px" }} />}
            <Typography variant="button">{text}</Typography>
          </IconButton>
        );
    }
  };

  return <>{statusSwitch(type)}</>;
};
