import { Dispatch, SetStateAction, useState } from "react";

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";

import { Step } from "../Step";
import { FieldValues, UseFormRegister } from "react-hook-form";

const ITEMS = [
  {
    name: "certainDate",
    label: "On a certain date & time",
    icon: <CalendarMonthRoundedIcon />,
  },
  {
    name: "flexible",
    label: "I’m flexible",
    icon: <HistoryRoundedIcon />,
  },
];
interface TimePropsType {
  register: UseFormRegister<FieldValues>;
  setTaskData?: any;
  taskData?: any;
}

export const Date = ({
  register,
  setTaskData,
  taskData,
}: TimePropsType): JSX.Element => {
  const [certainDate, setCertainDate] = useState<boolean>(false);
  const [flexibleDate, setFlexibleDate] = useState<boolean>(false);

  const { palette, breakpoints } = useTheme();
  const smallSize = useMediaQuery(breakpoints.down("md"));
  const under360 = useMediaQuery("(max-width:360px)");

  const handleFlexibleDate = () => {
    if (certainDate) {
      setCertainDate(false);
    }
    setFlexibleDate(!flexibleDate);
  };

  const handleCertainDate = () => {
    if (flexibleDate) {
      setFlexibleDate(false);
    }
    setCertainDate(!certainDate);
  };

  return (
    <Step title="Date & Time">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: { xs: "100%", sm: "558px" } }}>
          <Box>
            <Typography variant="h5" sx={{ color: palette.neutral.max }}>
              When do you need your task done?
            </Typography>
          </Box>
          {ITEMS.map(({ name, label, icon }, index) => (
            <Box
              component="label"
              htmlFor={`timeType-${name}`}
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                border: {
                  md: `${
                    taskData.timeType === name
                      ? `1px solid ${palette.primary.main}`
                      : `1px solid ${palette.neutral[100]}`
                  }`,
                  xs: `${
                    taskData.timeType === name
                      ? `2px solid ${palette.primary.main}`
                      : `2px solid ${palette.neutral[100]}`
                  }`,
                },
                outline:
                  taskData.timeType === name ? "3px solid transparent" : "",
                outlineWidth: "2px",
                outlineColor: { xs: "none", lg: palette.primary.main },
                borderRadius: "8px",
                py: under360 ? "12px" : { xs: "22px", md: "14px" },
                px: under360 ? "16px" : { xs: "24px", md: 3 },
                my: 2,
                svg: { mr: 1, color: palette.primary.main },
                input: { display: "none" },
              }}
              // onClick={handleCertainDate}
            >
              <input
                {...register("timeType", {
                  required: true,
                  onChange: (e) => {
                    e.persist();

                    setTaskData((current: any) => ({
                      ...current,
                      timeType: e.target?.value,
                    }));
                  },
                })}
                type="radio"
                name="timeType"
                value={name}
                id={`timeType-${name}`}
              />
              {icon}
              <Typography
                variant={smallSize ? "button" : "h6"}
                sx={{ color: palette.neutral.max }}
              >
                {label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Step>
  );
};
