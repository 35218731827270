import { getAnalytics, logEvent } from "firebase/analytics";
import { isDevelopment } from "utils";
import { useSelector } from "react-redux";
export const firebase_config = {
  apiKey: "AIzaSyBL26zOipjNHkqVJ7gZckb9IQy4NbzpE8o",
  authDomain: "jobtick-1-00.firebaseapp.com",
  databaseURL: "https://jobtick-1-00.firebaseio.com",
  projectId: "jobtick-1-00",
  storageBucket: "jobtick-1-00.appspot.com",
  messagingSenderId: "582071119574",
  appId: "1:582071119574:web:de397f4757302749b0a377",
  measurementId: "G-WHTR72LR72",
};

export const event_log = {
  click: {
    post_job: {
      details_1: "wa_click_pj_details_1_next",
      details_2: "wa_click_pj_details_2_next",
      date: "wa_click_pj_date_next",
      budget: "wa_click_pj_budget_submit",
      categories: "wa_click_pj_categories_next",
      done_view_job: "wa_click_pj_done_view_job",
      done_pj: "wa_click_pj_done_pj",
    },
  },
  page_view: {
    post_job: {
      details_1: "wa_page_view_pj_details_1",
      details_2: "wa_page_view_pj_details_2",
      date: "wa_page_view_pj_date",
      budget: "wa_page_view_pj_budget",
      categories: "wa_page_view_pj_categories",
      done: "wa_page_view_pj_done",
    },
  },
  api: {
    post_job: "wa_api_pj_tasks/create_v1",
  },
};
export function useEvent() {
  const account = useSelector((state) => state.account);
  const pushEvent = (eventName, data = {}) => {
    let bundle = {
      usr_name: account?.name ?? "guest",
      usr_id: account?.id,
      email: account?.email,
      phone_number: account?.mobile,
    };
    for (let i in Object.keys(data)) {
      const key = Object.keys(data)[i];
      let value = data[key];
      if (typeof value === "object") value = JSON.stringify(value);
      if (typeof value === "string" && value.length > 98) {
        value = value.substring(0, 98);
      }
      bundle = { ...bundle, [key]: value };
    }

    if (isDevelopment) console.log("event", eventName, bundle);
    else {
      try {
        const analytics = getAnalytics();
        return logEvent(analytics, eventName, bundle);
      } catch (e) {
        console.log(e);
      }
      return undefined;
    }
  };
  return {
    pushEvent,
  };
}
