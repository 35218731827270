import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const initialState = {
  filter: {
    searchQuery: "",
    sort_by: "Most Recent",
    sort_direction: "",
    task_type: "Both",
    current_lat: "43.641186",
    current_lng: "-79.37687",
    location: "Harbourfront",
    distance: 50,
    category: [],
    min_price: 5,
    max_price: 9999,
    getAlert: 0,
    show: false,
    // distance: Filter?.distance,

    // current_lat: Filter?.current_lat,
    // current_lng: Filter?.current_lng,
  },
};

const filterStore = (set) => ({
  filter: {
    searchQuery: "",
    sort_by: "Most Recent",
    sort_direction: "",
    task_type: "Both",
    current_lat: "43.641186",
    current_lng: "-79.37687",
    location: "Harbourfront",
    distance: 50,
    category: [],
    min_price: 5,
    max_price: 9999,
    getAlert: 0,
    show: false,
  },
  setFilter: (field, value) =>
    set((state) => {
      return {
        filter: {
          ...state.filter,
          [field]: value,
        },
      };
    }),
  reset: () => set(initialState),
});

const useFilterStore = create(
  devtools(persist(filterStore, { name: "filter" }))
);

export default useFilterStore;
