import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";

const PageNotFound = () => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width: { md: "100%", xs: "300px" },
        textAlign: "center",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img src="/images/Frame.svg" alt="404" />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h6" sx={{ mt: "46px" }}>
          Whoops..., 404 (Not Found)
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          variant="body1"
          sx={{ mt: "16px", color: palette.neutral[700] }}
        >
          Your requested URL was not found on our server.
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="submit"
          sx={{
            mt: "40px",
            color: palette.primary.main,
          }}
        >
          Contact Support
        </Button>
      </Box>
    </Box>
  );
};

export default PageNotFound;
