import API, { APIV2 } from "./_config"

export function fetchNotifications(page) {
    return API.get(`/notifications`, {
            params: {
                page
            }
        })
        .then(response => {
            return response.data
        })
        .catch(e => {
            throw (e)
        })
}

export function fetchUnreadNotificationsCount() {
    return API.get(`/notifications/unread-count`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            throw (e)
        })
}

export function markNotificationsAsRead() {
    return API.post(`/notifications/mark-all-read`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            throw (e)
        })
}

export function fetchNotificationSettings(type) {
    return APIV2.get(`/notifications/settings`, {
            params: {
                type
            }
        })
        .then(response => {
            return response.data
        })
        .catch(e => {
            throw (e)
        })
}


export function setNotificationSetting(type,item,value) {
    return APIV2.post(`/notifications/settings`,{type,item,value})
        .then(response => {
            return response.data
        })
        .catch(e => {
            throw (e)
        })
}