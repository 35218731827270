import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const notificationsCountStore = (set) => ({
  NotificationUnread: { unread_count: 0 },

  setNotificationUnread: (field, value) =>
    set((state) => {
      return {
        NotificationUnread: {
          ...state.filter,
          [field]: value,
        },
      };
    }),
});

const useNotificationsCountStore = create(
  devtools(persist(notificationsCountStore, { name: "NotificationsCount" }))
);

export default useNotificationsCountStore;
